import React from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Form,
} from "react-bootstrap";
import {
  calculateAsymmetricRCV,
  modelAsymmetricRCV,
  percentile_z,
} from "./asymmetricCalculator";
import NumberFormat from "react-number-format";

import AsymmetricRcvCalculationResults from "./AsymmetricRcvCalculationResults";

class AsymmetricRcvCalculation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      isCalculated: false,
      calculationData: null,
      items: [],
      cvi: this.props.cvi,
      cva: 0.0,
      cviUpper: this.props.cviUpper,
      cviLower: this.props.cviLower,
      pvalue: 0.05,
      zvalue: 1.64,
      medianRCV: [],
      lowerRCV: [],
      upperRCV: [],
      modellingDataSet: [],
      zOptions: [
        {
          text: "0.025",
          value: "1.96",
        },
        {
          text: "0.025",
          value: "1.96",
        },
        {
          checked: true,
          text: "0.05",
          value: "1.64",
        },
        {
          text: "0.025",
          value: "1.96",
        },
      ],
    };

    this.handleCVIChange = this.handleCVIChange.bind(this);
    this.handleCVIUpperChange = this.handleCVIUpperChange.bind(this);
    this.handleCVILowerChange = this.handleCVILowerChange.bind(this);
    this.handleCVAChange = this.handleCVAChange.bind(this);
    this.handleChangeZSelect = this.handleChangeZSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getModellingData() {
    this.setState({
      modellingDataSet: modelAsymmetricRCV(this.state.cvi, this.state.cva),
    });
  }

  handleCVIChange(event) {
    if (event.target.value >= 0) {
      this.setState(
        {
          cvi: event.target.value,
          modellingDataSet: modelAsymmetricRCV(this.state.cvi, this.state.cva),
        },
        () => this.handleSubmit(event)
      );
    }
  }

  handleCVIUpperChange(event) {
    if (event.target.value >= 0) {
      this.setState({ cviUpper: event.target.value }, () =>
        this.handleSubmit(event)
      );
    }
  }

  handleCVILowerChange(event) {
    if (event.target.value >= 0) {
      this.setState({ cviLower: event.target.value }, () =>
        this.handleSubmit(event)
      );
    }
  }

  handleCVAChange(event) {
    this.setState({ cva: event.target.value }, () => this.handleSubmit(event));
  }

  handleChangeZSelect(event) {
    // this.setState({ zvalue: Number(event[0]) }, () => this.handleSubmit(event));

    var p = Number(event.target.value);
    var z = percentile_z(p);
    this.setState({ zvalue: Number(z) }, () =>
      this.handleSubmit(event)
    );
    this.setState({ pvalue: Number(p) }, () => this.handleSubmit(event));
  }

  handleSubmit(event) {
    this.setState({
      medianRCV: calculateAsymmetricRCV(
        this.state.cvi,
        this.state.cva,
        this.state.zvalue
      ),
    });

    this.setState({
      lowerRCV: calculateAsymmetricRCV(
        this.state.cviLower,
        this.state.cva,
        this.state.zvalue
      ),
    });

    this.setState({
      upperRCV: calculateAsymmetricRCV(
        this.state.cviUpper,
        this.state.cva,
        this.state.zvalue
      ),
    });
    // console.log("Modelling Data Updated");
    // console.log(this.state.modellingDataSet);

    this.setState({ isCalculated: true }, () => this.getModellingData());
  }

  render() {
    const isCalculated = this.state.isCalculated;
    return (
      <Container>
        <Card bg="warning">
          <Card.Body>
            <Card.Title> Enter Values </Card.Title>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col md="4">
                    <Form.Label>% Within-subject (CVI) estimate</Form.Label>
                    <NumberFormat
                      type="number"
                      value={this.state.cvi}
                      className="font-monospace form-control"
                      decimalScale="1"
                      onChange={this.handleCVIChange}
                    />
                  </Col>
                  <Col md="4">
                    <Form.Label>Lower 95% Confidence Interval</Form.Label>
                    <NumberFormat
                      type="number"
                      value={this.state.cviLower}
                      className="font-monospace form-control"
                      decimalScale="1"
                      onChange={this.handleCVILowerChange}
                    />
                  </Col>
                  <Col md="4">
                    <Form.Label>Upper 95% Confidence Interval</Form.Label>
                    <NumberFormat
                      type="number"
                      value={this.state.cviUpper}
                      className="font-monospace form-control"
                      decimalScale="1"
                      onChange={this.handleCVIUpperChange}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col md="4">
                  <Form.Label>% Analytical CV</Form.Label>
                  <Form.Control
                    type="number"
                    value={this.state.cva}
                    onChange={this.handleCVAChange}
                    className="font-monospace"
                  />
                </Col>
                <Col md="6">
                  <Form.Label>Enter probability</Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={this.state.pvalue}
                    onChange={this.handleChangeZSelect}
                    className="font-monospace"
                  />
                </Col>
                <Col md="2">
                  <Form.Label>z-value</Form.Label>
                  <Form.Control
                    type="text"
                    id="pDisabled"
                    className="form-control"
                    placeholder={this.state.zvalue}
                    disabled
                    className="font-monospace"
                  />
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <Row>
          <Col>
            {isCalculated ? (
              <>
                <hr></hr>
                <AsymmetricRcvCalculationResults
                  analyte={this.props.analyte}
                  medianRCV={this.state.medianRCV}
                  lowerRCV={this.state.lowerRCV}
                  upperRCV={this.state.upperRCV}
                  cviUpper={Number(this.state.cviUpper)}
                  cviLower={Number(this.state.cviLower)}
                  cva={Number(this.state.cva)}
                  cvi={Number(this.state.cvi)}
                  zvalue={this.state.zvalue}
                  modellingDataSet={this.state.modellingDataSet}
                />
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AsymmetricRcvCalculation;
