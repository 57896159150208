import React from "react";
import PatientRCVCalculator from "./PatientRCVCalculator";
import { Container, Col, Row, Card, Form, Button, Table } from "react-bootstrap";

class AsymmetricRcvCalculationResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cvi: this.props.cvi,
      cva: this.props.cva,
      zvalue: this.props.zvalue,
      cviLower: this.props.cviLower,
      cviUpper: this.props.cviUpper,
      medianRCV: this.props.medianRCV,
      lowerRCV: this.props.lowerRCV,
      upperRCV: this.props.upperRCV,
      modellingDataSet: this.props.modellingDataSet,
    };

  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className="aps-calculation-result">
                <h3 className="card-title">Results</h3>

                <p>
                In the table below, you will find the asymmetric RCVs for {this.props.analyte.display_name}, 
                with separate RCV values for increase and decrease, presented with 95% confidence intervals (CI). 
                The RCVs represent the unidirectional change between consecutive test results in a patient that can be 
                explained by the given analytical (CVA) and within-subject biological (CVI) variation, at the chosen probability.
                </p>

                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Median</th>
                      <th>Lower CI</th>
                      <th>Upper CI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-monospace">
                      <td>CVI</td>
                      <td>{this.props.cvi.toFixed(2)}</td>
                      <td>{this.props.cviLower.toFixed(2)}</td>
                      <td>{this.props.cviUpper.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>CVA</td>
                      <td>{this.props.cva.toFixed(2)}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="font-monospace">
                      <td>RCV% Increase</td>
                      <td>{this.props.medianRCV["rcvUp"].toFixed(1)}</td>
                      <td>{this.props.lowerRCV["rcvUp"].toFixed(1)}</td>
                      <td>{this.props.upperRCV["rcvUp"].toFixed(1)}</td>
                    </tr>
                    <tr className="font-monospace">
                      <td>RCV% Decrease</td>
                      <td>{this.props.medianRCV["rcvDown"].toFixed(1)}</td>
                      <td>{this.props.lowerRCV["rcvDown"].toFixed(1)}</td>
                      <td>{this.props.upperRCV["rcvDown"].toFixed(1)}</td>
                    </tr>
                  </tbody>
                </Table>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
          <hr/>
            <PatientRCVCalculator
              medianRCV={this.props.medianRCV}
              lowerRCV={this.props.lowerRCV}
              upperRCV={this.props.upperRCV}
              measurand={this.props.measurand}
              cviUpper={Number(this.props.cviUpper)}
              cviLower={Number(this.props.cviLower)}
              cva={Number(this.props.cva)}
              cvi={Number(this.props.cvi)}
              modellingDataSet={this.props.modellingDataSet}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default AsymmetricRcvCalculationResults;
