import React from "react";
import BIVACDatasetSummary from "./BIVACDatasetSummary";
import ViewSpecificationButton from "../ui/ViewSpecificationButton";
import { Col, Container, Row } from "react-bootstrap";
import BvSpecification from "../../types/models/BvSpecification";

type SpecificationsProps = {
  specification: BvSpecification;
};

function BIVACDataset({ specification }: SpecificationsProps) {
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col>
            <BIVACDatasetSummary specification={specification} />
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 6 }}>
            <ViewSpecificationButton specification={specification} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BIVACDataset;
