import React from "react";
import Analyte from "../../types/models/Analyte";
import MetaAnalysis from "../../types/models/MetaAnalysis";
import Aps from "../Services/Calculators/APS/Aps";
import AsymmetricRcv from "../Services/Calculators/RCV/AsymmetricRcv";

type MetaCalculationToolsProps = {
  analyte: Analyte;
  within_data?: MetaAnalysis;
  between_data?: MetaAnalysis;
};

function MetaCalculationsTools({
  analyte,
  within_data,
  between_data,
}: MetaCalculationToolsProps) {
  
  var return_value: Array<any> = [];

  if (between_data && within_data) {
    return_value.push(
      <Aps
        analyte={analyte}
        within_data={within_data}
        between_data={between_data}
        key={within_data.id + "aps"}
      />
    );
  }

  if (within_data) {
    return_value.push(
      <AsymmetricRcv
        analyte={analyte}
        cvi={within_data!.median ? within_data!.median : null}
        cviLower={within_data!.lower}
        cviUpper={within_data!.upper}
        key={within_data!.id + "rcv"}
      />
    );
  }

  return <React.Fragment>{return_value}</React.Fragment>;

}

export default MetaCalculationsTools;
