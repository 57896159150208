import React from "react";
import BvSpecification from "../../types/models/BvSpecification";

type EditSpecificationButtonProps = {
  specification: BvSpecification;
};

function EditSpecificationButton({
  specification,
}: EditSpecificationButtonProps) {
  return (
    <a
      className="btn btn-success btn-sm btn-block"
      href={"/bv_specifications/" + specification.id + "/edit"}
    >
      Edit Dataset
    </a>
  );
}

export default EditSpecificationButton;
