import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import IsLoading from "../ui/IsLoading";
import { GridLoader } from "react-spinners";
import CiteReference from "../ui/CiteReference";
import BIVACTools from "../ui/BIVACTools";
import ViewSpecificationButton from "../ui/ViewSpecificationButton";
import filterFactory, {
  textFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import Analyte from "../Analyte/Analyte";

const RemoteAll = ({
  columns,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
}) => (
  <div>
    <BootstrapTable
      remote
      keyField="id"
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      filter={filterFactory()}
      filterPosition="top"
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
    />
  </div>
);

RemoteAll.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

const defaultSorted = [
  {
    dataField: "name",
    order: "desc",
  },
];

class BVSpecificationsIndex extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    // Set the state directly. Use props if necessary.
    this.state = {
      bv_specifications_dataset: [],
      loading: true,
      page: 1,
      sizePerPage: 10,
      data: [],
      totalSize: 0,
    };
  }

  columns = [

    {
      dataField: "measurand",
      text: "Measurand",
      // headerStyle: { width: "30%" },
      sort: true,
      formatter: (cellContent, row) => {
        return <Analyte analyte={row.analyte}/>
      },
      headerClasses: "bg-secondary",
      filter: textFilter({
        defaultValue: "",
        placeholder: "Search....",
      }),
    },
    {
      dataField: "reference.title",
      text: "Specification",
      // headerStyle: { width: "20%" },
      sort: true,
      formatter: (cellContent, row) => {
        return (this.SpecDisplay(row))
      },
      headerClasses: "bg-secondary",

    },
    {
      dataField: "reference",
      text: "Reference",
      // headerStyle: { width: "40%" },
      headerClasses: "bg-secondary",
      formatter: (cellContent, row) => {
        return (
          <CiteReference reference={row.reference} />
        );
      },
    },
    {
      dataField: "tools",
      text: "",
      // headerStyle: { width: "10%" },
      headerClasses: "bg-secondary",
      formatter: (cellContent, row) => {
        return (
          <BIVACTools user={this.props.user_id} specification={row} />
        );
      },
    },
  ];

  SpecDisplay = (row) => {
    return (
    <>
        <div
          dangerouslySetInnerHTML={{
            __html: 
              row.score_display
          }}
        >
        </div>
    <ViewSpecificationButton specification={row} /></>)
    
  }

  loadingText() {
    if (this.state.loading) {
      return "Loading";
    }
  }

  async componentDidMount() {

      await axios.get("/api/bv_specifications").then((response) => {
        if (response.data.code == "success") {
          this.setState({
            bv_specifications_dataset: response.data.data,
            data: response.data.data.slice(0, 0 + 10),
            totalSize: response.data.data.length,
            loading: false,
          });
        }
      });

  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;

    let result = this.state.bv_specifications_dataset;

    // Handle column filters
    result = result.filter((row) => {
      let valid = true;
      for (const dataField in filters) {
        const { filterVal, filterType, comparator } = filters[dataField];

        if (filterType === "TEXT") {
          if (comparator === Comparator.LIKE) {
            // const title = row['reference'].title.toString()
            const measurand = row['analyte'].display_name.toString()
            const id = row['id'].toString()
            // const intermediate = title.concat(measurand)
            // to remove sarching title as requested
            const intermediate = measurand

            valid = intermediate.concat(id).toLowerCase().indexOf(filterVal.toLowerCase()) > -1;

          } else {
            valid = row[dataField] === filterVal;
          }
        }
        if (!valid) break;
      }
      return valid;
    });

    // Handle column sort
    if (sortOrder === "asc") {
      result = result.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = result.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }

    this.setState(() => ({
      page,
      data: result.slice(currentIndex, currentIndex + sizePerPage),
      totalSize: result.length,
      sizePerPage,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <IsLoading IsLoading={this.state.loading} />
        <div className="d-flex justify-content-center">
          <GridLoader size={15} color={"#123abc"} loading={this.state.loading}>
            <span className="visually-hidden">Loading...</span>
          </GridLoader>
        </div>
        <div>Total Number of BVIAC datasets found: {this.state.totalSize}</div>

        <RemoteAll
          columns={this.columns}
          data={this.state.data}
          page={this.state.page}
          sizePerPage={this.state.sizePerPage}
          totalSize={this.state.totalSize}
          onTableChange={this.handleTableChange}
        />
      </React.Fragment>
    );
  }
}

export default BVSpecificationsIndex;
