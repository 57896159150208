import React, { useEffect, useState } from "react";
import axios from "axios";
import {StateOfWellBeing as StateOfWellBeingType} from "../../types/models/StateOfWellBeing";

type StateOfWellBeingPropsType = {
  state_of_well_being?: StateOfWellBeingType;
  id?: number;
  simple: boolean;
};

function StateOfWellBeing({
  state_of_well_being,
  simple,
  id,
}: StateOfWellBeingPropsType) {
  const [sowb, setSowb] = useState<StateOfWellBeingType>();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get("/api/state_of_well_beings/" + id, {
      });

      setSowb(result.data.state_of_well_being);
    };

    if (!state_of_well_being) {
      fetchData();
    } else {
      setSowb(state_of_well_being);
    }

  }, []);

  const simple_layout = () : JSX.Element => {
    return sowb ? <small><strong>{sowb!.state_of_well_being_descriptor_expansion}</strong></small> : <div></div>
  }

  const full_layout = () : JSX.Element => {

    return sowb ? (
      <div>
        <p><small>{sowb!.state_of_well_being_descriptor_expansion}</small></p>
        <p>
          <small>{sowb!.state_of_well_being_descriptor_explanation}</small>
        </p>
      </div>
    ) : (
      <div></div>
    );
  }

  const layout = () : JSX.Element => {
    if (simple) {
      return simple_layout()
    } else {
      return full_layout()
    }
  }

  return sowb ? layout(): <div>Loading....</div>;
  
}

export default StateOfWellBeing;
