import React, { useState, useEffect } from "react";

function MetaAnalysisCount(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [metaData, setMetaData] = useState([]);
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("/api/meta")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setMetaData(result.meta_data);

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <p id="meta_analysis_count" className="meta_analysis_count">{metaData.total_metas}</p>
    );
  }
}

export default MetaAnalysisCount;