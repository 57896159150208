import React from "react";
import { calculateAPS } from "./apsCalculator";
import ApsCalculationResults from "./ApsCalculationResults";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import Analyte from "../../../../types/models/Analyte";
import MetaAnalysis from "../../../../types/models/MetaAnalysis";

type ApsCalculationProps = {
  analyte: Analyte;
  within_data: MetaAnalysis;
  between_data: MetaAnalysis;
};

type ApsCalculationState = {
  error?: string;
  modal?: boolean;
  isClicked?: boolean;
  isLoaded?: boolean;
  isCalculated?: boolean;
  cvi?: number;
  cvg?: number;
  within?: MetaAnalysis;
  between?: MetaAnalysis;
  minimumSpecs?: {}
  desirableSpecs?: {};
  optimumSpecs?: {};
};

class ApsCalculation extends React.Component<
  ApsCalculationProps,
  ApsCalculationState
> {
  constructor(props: ApsCalculationProps) {
    super(props);

    this.state = {
      error: "",
      isLoaded: false,
      isCalculated: false,
      cvi: 0,
      cvg: 0,
      within: this.props.within_data,
      between: this.props.between_data,
      minimumSpecs: {},
      desirableSpecs: {},
      optimumSpecs: {},
    };

    this.handleCVIChange = this.handleCVIChange.bind(this);
    this.handleCVGChange = this.handleCVGChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.setVariation();
  }

  setVariation = () => {
    // this.state.metacalculations.forEach(this.extractVariation);
    this.extractVariation(this.props.between_data);
    this.extractVariation(this.props.within_data);
  };

  extractVariation = (value: MetaAnalysis) => {
    if (value.var_type == ":cvg") {
      this.setState({ cvg: value.median.toFixed(1) });
    }

    if (value.var_type == ":cvi") {
      this.setState({ cvi: value.median.toFixed(1) });
    }
  };

  handleCVIChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      {
        cvi: e.currentTarget.value,
      },
      () => this.handleSubmit(e)
    );
  };

  handleCVGChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      {
        cvg: e.currentTarget.value,
      },
      () => this.handleSubmit(e)
    );
  };

  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const returnData = calculateAPS(this.state.cvi, this.state.cvg);

    this.setState({ isCalculated: true });

    this.setState({
      minimumSpecs: {
        minimumCV: returnData.minimumCV,
        minimumBias: returnData.minimumBias,
        minimumTE: returnData.minimumTE,
        minimumMAU: returnData.minimumMAU,
      },
    });

    this.setState({
      desirableSpecs: {
        desirableCV: returnData.desirableCV,
        desirableBias: returnData.desirableBias,
        desirableTE: returnData.desirableTE,
        desirableMAU: returnData.desirableMAU,
      },
    });

    this.setState({
      optimumSpecs: {
        optimumCV: returnData.optimumCV,
        optimumBias: returnData.optimumBias,
        optimumTE: returnData.optimumTE,
        optimumMAU: returnData.optimumMAU,
      },
    });
  };
  render() {
    const isCalculated = this.state.isCalculated;
    return (
      <Container>
        <Card className="bg-light">
          <Card.Body>
            <Card.Title>{this.props.analyte.display_name}</Card.Title>
            <b>Enter Values</b>
            <Form>
              <Row>
                <Col>
                  <Form.Label
                    label="% Within-subject (CVI) estimate "
                    className="mb-3"
                  >
                    % Within-subject (CVI) estimate{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={this.state.cvi}
                    onChange={this.handleCVIChange}
                  />
                </Col>
                <Col>
                  <Form.Label
                    label="% Between-subject (CVG) estimate "
                    className="mb-3"
                  >
                    % Between-subject (CVG) estimate{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={this.state.cvg}
                    onChange={this.handleCVGChange}
                  />
                </Col>
              </Row>
              {isCalculated ? (
                ""
              ) : (
                <div className="text-center py-4 mt-3">
                  <Button onClick={this.handleSubmit} variant="success">
                    Calculate
                  </Button>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>

        {isCalculated ? (
          <ApsCalculationResults
            minimumSpecs={this.state.minimumSpecs}
            desirableSpecs={this.state.desirableSpecs}
            optimumSpecs={this.state.optimumSpecs}
          />
        ) : (
          ""
        )}
      </Container>
    );
  }
}

export default ApsCalculation;
