import React from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      results: [],
      activeSuggestion: "",
      selectedIndex: -1,
      filteredSuggestions: [],
      showSuggestions: false,
      loading: false,
      message: "",
    };
    this.cancel = "";

    this.renderSearchResults = this.renderSearchResults.bind(this);
  }

  handleOnInputChange = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ query, results: {}, message: "", selectedIndex: -1 });
    } else {
      this.setState({ query, loading: true, message: "" }, () => {
        this.fetchSearchResults(query);
      });
    }
  };

  /**
   * Fetch the search results and update the state with the result.
   *
   * @param {String} query Search Query.
   *
   */
  fetchSearchResults = (query) => {
    // By default the limit of results is 20
    const searchUrl = `/autocomplete?query=${query}`;

    if (this.cancel) {
      // Cancel the previous request before making a new request
      this.cancel.cancel();
    }
    // Create a new CancelToken
    this.cancel = axios.CancelToken.source();
    axios
      .get(searchUrl, {
        cancelToken: this.cancel.token,
      })
      .then((res) => {
        const resultNotFoundMsg = !res.data.length
          ? "There are no more search results. Please try a new search."
          : "";
        this.setState({
          results: res.data,
          message: resultNotFoundMsg,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: "Failed to fetch results.Please check network",
          });
        }
      });
  };

  renderSearchResults = () => {
    const renderHighLight = (result, searchUrl, index) => {
      if (index == this.state.selectedIndex) {
        return (
          <div className="result-item-highlight">
            <a key={result.id} href={searchUrl}>
              <h5>{result.display_name}</h5>
            </a>
          </div>
        );
      }
      return (
        <div className="result-item">
          <a key={result.id} href={searchUrl}>
            <h6>{result.display_name}</h6>
          </a>
        </div>
      );
    };

    if (Object.keys(this.state).length && this.state.results.length > 0) {
      return (
        <div className="results-container">
          {this.state.results.map((result, index) => {
            const searchUrl = `/search?query=${result.display_name}`;
            return (
              <div key={result.id}>
                {renderHighLight(result, searchUrl, index)}
              </div>
            );
          })}
        </div>
      );
    }
  };

  onKeyDown = (e) => {
    const { selectedIndex, results, activeSuggestion } = this.state;

    if (e.keyCode === 38) {
      if (selectedIndex > 0) {
        this.setState(
          {
            selectedIndex: selectedIndex - 1,
          },
          () => {
            this.setState({ query: results[this.state.selectedIndex].display_name });
          }
        );
      }
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (selectedIndex +1 < results.length) {
        this.setState(
          {
            selectedIndex: selectedIndex + 1,
          },
          () => {
            this.setState({
              query: results[this.state.selectedIndex].display_name,
            });
          }
        );
      }
    }
  };

  render() {
    const { query, loading, message } = this.state;
    return (
      <React.Fragment>
        <div className="search-wrapper">
          <form
            autoComplete="new-password"
            action="/search"
            className="form-inline"
          >
            <div className="input-group md-form form-sm form-1 pl-0">
              <input
                className="form-control my-0 py-1"
                autoComplete="off"
                type="text"
                placeholder="Search"
                type="text"
                value={this.state.query}
                id="search-input"
                placeholder="Search for analyte"
                onChange={this.handleOnInputChange}
                onKeyDown={this.onKeyDown}
                aria-label="Search"
                name="query"
              />
              <Button variant={this.props.buttonColor} type="submit" size="sm">
                Search
              </Button>
            </div>

            {/*	Error Message*/}
            {message && <p className="message">{message}</p>}

            {/*	Loader*/}
          </form>
          {this.renderSearchResults()}
        </div>
      </React.Fragment>
    );
  }
}

export default Search;
