import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

type MetaCalculationCalculatorProps = {
  data: string;
  calculationResults?: MetaAnalysisResult;
  setState: any;
  rrest: string;
};

function MetaCalculationCalculator({
  data,
  setState,
  calculationResults,
  rrest,
}: MetaCalculationCalculatorProps) {
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      const api_data = await fetch(rrest, {
        method: "post",
        mode: "cors",
        body: data,
      });
      // convert the data to json
      const json = await api_data.json();

      const results: MetaAnalysisResult = {
        N: json.N[0],
        "W.Median": json["W.Median"][0],
        Range_lower: json.Range_lower[0],
        Range_upper: json.Range_upper[0],
      };

      // set state with the result
      setState(results);
      setIsLoaded(true);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(function (error) {
        console.log(error);
        setIsLoaded(true);
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: unable to calculate</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <b>Raw Metacalculation Results</b>
        <Table
          striped
          bordered
          hover
          id="validation_table"
          className="dataTable"
        >
          <thead>
            <tr>
              <th scope="col">N</th>
              <th scope="col">Median</th>
              <th scope="col">Range Lower</th>
              <th scope="col">Range Upper</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {calculationResults ? (
                <>
                  <td>{calculationResults!.N}</td>
                  <td>{calculationResults!["W.Median"]}</td>
                  <td>{calculationResults!.Range_lower}</td>
                  <td>{calculationResults!.Range_upper}</td>
                </>
              ) : (
                <>
                  <td>Unable to Calculate</td>
                  <td>Unable to Calculate</td>
                  <td>Unable to Calculate</td>
                  <td>Unable to Calculate</td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

export default MetaCalculationCalculator;
