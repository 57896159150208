function uniqueNumbersFromArray(
  reference_array: number[]
): number[] {

  let result2 = reference_array.map((a) => parseInt(a));

  var x: number[] = [];
  var merged = x.concat.apply([], result2);
  let uniqueItems = [...new Set(merged)];
  return uniqueItems;
}

export default uniqueNumbersFromArray;
