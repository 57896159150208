import React from "react";
import axios from "axios";
import { Table, Alert } from "react-bootstrap";
import Reference from "./Reference";
import StateOfWellBeing from "../StateOfWellBeing/StateOfWellBeing";
import SamplingIntervals from "../ui/SamplingIntervals";


class ReferencesForMetaAnalysis extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    dataSet: [],
    uniqueItems: [],
  };

  async componentDidMount() {
    // gymnastics to reduce ref id to unique
    let result = this.props.metas.map((a) =>
      a.references_array.replace("[", "").replace("]", "").split(",")
    );
    let result2 = result.map((a) => a.map((b) => parseInt(b.trim())));
    var merged = [].concat.apply([], result2);

    this.setState({ uniqueItems: [...new Set(merged)] });

    await axios
      .get("/api/analyte_specifications/" + this.props.analyte_id)
      .then((response) => {
        this.setState({ dataSet: response.data });
      });
  }

  renderAllReferenceForAnalyte = (item) => {
    let backgroundColor = "table-warning";

    if (this.state.uniqueItems.includes(item.id, 0)) {
      backgroundColor = "table-success";
    }

    return (
      <tr key={item.id} className={backgroundColor}>
        <td>
          <Reference reference={item.reference} />
        </td>
        <td>
          <a href={"/bv_specifications/" + item.id}>
            View Dataset
          </a>
        </td>
        <td>{item.estimates_of_cvi}</td>
        <td>{item.estimates_of_cvg}</td>
        <td>{item.sex_for_meta_analysis}</td>
        <td>{item.age_mean}</td>
        <td>
          <StateOfWellBeing
            simple={true}
            stateOfWellBeing={item.state_of_well_being}
          />
        </td>
        <td>
          <b>
            <p className="text-success">{item.matrix.matrix_expansion}</p>
          </b>
        </td>
        <td><SamplingIntervals samplingInterval={item.samples_per} intervalUnits={item.interval_units}/></td>
      </tr>
    );
  };

  render() {
    return (
      <>
        <h3>Colour Key for References</h3>
        <Alert key={"include"} variant={"success"}>
          Included In Meta Analysis
        </Alert>
        <Alert key={"not_include"} variant={"warning"}>
          Not Included In Meta Analysis
        </Alert>
        <hr></hr>
        <Table>
          <thead>
            <tr>
              <th>
                <h3>Reference</h3>
              </th>
              <th>
                Dataset
              </th>
              <th>Estimate of CVI</th>
              <th>Estimate of CVG</th>
              <th>Gender</th>
              <th>Age</th>
              <th>State of Well Being</th>
              <th>Matrix</th>
              <th>Sampling Interval</th>
            </tr>
          </thead>
          <tbody>
            {this.state.dataSet.map(this.renderAllReferenceForAnalyte)}
          </tbody>
        </Table>
      </>
    );
  }
}

export default ReferencesForMetaAnalysis;
