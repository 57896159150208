import React, { useState } from "react";
import EditReferenceButton from "../ui/EditReferenceButton";
import {
  Modal,
  Button,
  ButtonGroup,
  Table,
  Container,
  NavLink,
} from "react-bootstrap";
import User from "../../types/models/User";
import { Reference as ReferenceType } from "../../types/models/Reference";

type ReferencePropsType = {
  reference?: ReferenceType,
  user: User
};

function ReferenceNoData({ reference, user }: ReferencePropsType) {
  const [show, setShow] = useState(false);

  const ToolBar = () => {
    if (user) {
      return (
        <>
          <ButtonGroup>
            <Button onClick={() => setShow(!show)} size="sm" variant="success">
              View
            </Button>
            <EditReferenceButton reference={reference!} user={user} />
          </ButtonGroup>
        </>
      );
    }
    return <div></div>;
  };

  const popover = (): JSX.Element => {
    return (
      <div>
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Title</td>
                <td>{reference!.title}</td>
              </tr>
              <tr>
                <td>Authors</td>
                <td>{reference!.author}</td>
              </tr>

              <tr>
                <td>Year of Publication</td>
                <td>{reference!.year_of_publication}</td>
              </tr>
              <tr>
                <td>Pubmed Link</td>
                <td>
                  <a href={reference!.pubmed_link}>{reference!.pubmed_link}</a>
                </td>
              </tr>
              <tr>
                <td>Pages</td>
                <td>{reference!.pages}</td>
              </tr>
              <tr>
                <td>Volume</td>
                <td>{reference!.volume}</td>
              </tr>

              <tr>
                <td>doi</td>
                <td>{reference!.doi}</td>
              </tr>
              <tr>
                <td>Links</td>
                <td>{reference!.link}</td>
              </tr>
              <tr>
                <td>Article ID</td>
                <td>{reference!.link_to_spanish_database}</td>
              </tr>
              <tr>
                <td>Files</td>
                <td>
                  <a href={reference!.article_url}>
                    {reference!.article_file_name}
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    );
  };

  const render = (): JSX.Element => {
    return (
      <>
        <NavLink className="fs-5" onClick={() => setShow(!show)}>
          {reference!.title}
          <br />
          <div>
            <p className="fs-6 mt-2 text-wrap">
              {reference!.author}, {reference!.journal}, {reference!.pages},{" "}
              {reference!.volume}, {reference!.year_of_publication}
            </p>
          </div>
        </NavLink>

        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => setShow(!show)}>
            <Modal.Title>Reference Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>{popover()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(!show)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="text-center">
          <ToolBar />
        </div>
      </>
    );
  };

  return <div>{reference ? <div>{render()}</div> : <div>Loading</div>}</div>;
}

export default ReferenceNoData;
