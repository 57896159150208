import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import AdminMenu from './AdminMenu';
import UserMenu from "./UserMenu";
import Search from "../Search/Search";
import NavBar from "./NavBar";
import Image from "../../../assets/images/eflm_logo_reversed.png";


class NavbarDouble extends React.Component {
    componentDidMount() { }

    render() {
        return (
            <div>
                <Navbar bg="primary" variant="dark" sticky="top" expand="lg">
                    <Navbar.Toggle aria-controls="top-nav"/>
                    
                        <Navbar.Brand href="/">
                            <img
                                src={Image}
                                className="d-inline-block align-top"
                                alt="EFLM"
                            />
                        </Navbar.Brand>
                        <Navbar.Collapse id="top-nav">
                        <Nav className="me-auto">
                            <UserMenu user_id={this.props.user_id} />
                            <AdminMenu user_id={this.props.user_id} />
                        </Nav>
                        <Nav className="justify-content-end">
                            <Navbar.Text>
                                <Search buttonColor='secondary' />
                            </Navbar.Text>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                <NavBar />
            </div>
        );
    }
}

export default NavbarDouble;