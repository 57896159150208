import React, { useState, useEffect, RefObject } from "react";
import {  Table } from "react-bootstrap";
import BvSpecification from "../../types/models/BvSpecification";
import Reference from "./Reference";
import StateOfWellBeing from "../StateOfWellBeing/StateOfWellBeing";
import SamplingIntervals from "../ui/SamplingIntervals";
import User from "../../types/models/User";
import Matrix from "../Matrix/Matrix";

type ReferencesForMeasurandType = {
  analyte_id: number;
  user: User;
};

function ReferencesForMeasurand({
  analyte_id,
  user,
}: ReferencesForMeasurandType): JSX.Element {
  const [dataset, setDataset] = useState([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      // get the data from the api
      const api_data = await fetch(
        "/api/analyte_specifications/" + analyte_id,
        {
          method: "get",
        }
      );
      // convert the data to json
      const json = await api_data.json();

      if (isMounted) {
        setDataset(json);
        setIsLoaded(true);
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(function (error) {
        setIsLoaded(true);
        setError(error);
        console.log(error);
      });

      return () => {
        isMounted = false;
      };
  }, []);

  const renderAllReferenceForAnalyte = (item: BvSpecification) => {
    return (
      <tr key={item.id}>
        <td>
          <Reference reference={item.reference} user={user} />
        </td>
        <td className="align-middle">
          <a
            className="btn btn-primary btn-sm"
            href={"/bv_specifications/" + item.id}
          >
            View
          </a>
        </td>
        <td className="fs-5 align-middle font-monospace">
          {item.estimates_of_cvi}
        </td>
        <td className="fs-5 align-middle font-monospace">
          {item.estimates_of_cvg}
        </td>
        <td className="fs-6 align-middle text-nowrap col-sm-auto">
          {item.sex_for_meta_analysis}
        </td>
        <td className="fs-6 align-middle">{item.age_mean}</td>
        <td className="fs-6 align-middle white-space ">
          <StateOfWellBeing
            simple={true}
            state_of_well_being={item.state_of_well_being}
          />
        </td>
        <td className="fs-6 align-middle">
          <b>
            <Matrix id={item.matrix.id} />
          </b>
        </td>
        <td className="fs-6 align-middle">
          <SamplingIntervals
            samples_per={item.samples_per}
            interval_units={item.interval_units}
          />
        </td>
      </tr>
    );
  };

  if (isLoaded && dataset && dataset.length > 0)
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>
                Reference
              </th>
              <th>Dataset</th>
              <th>Estimate of CVI</th>
              <th>Estimate of CVG</th>
              <th className="col-sm-auto">Gender</th>
              <th>Age</th>
              <th>State of Well Being</th>
              <th>Matrix</th>
              <th>Sampling Interval</th>
            </tr>
          </thead>
          <tbody>{dataset.map(renderAllReferenceForAnalyte)}</tbody>
        </Table>
      </>
    );
  else
    return isLoaded ? (
      <div>No datasets in database</div>
    ) : (
      <span>Loading.....</span>
    );
}

export default ReferencesForMeasurand;
