import React from "react";
import { Modal, Button } from "react-bootstrap";
import Analyte from "../../../../types/models/Analyte";
import MetaAnalysis from "../../../../types/models/MetaAnalysis";
import ApsCalculation from "./ApsCalculation";

type ApsProps = {
  analyte: Analyte;
  within_data: MetaAnalysis;
  between_data: MetaAnalysis;
};

type ApsState = {
  error?: string;
  modal?: boolean;
  isClicked?: boolean;
}

class Aps extends React.Component<ApsProps, ApsState> {
  constructor(props: ApsProps) {
    super(props);

    this.state = {
            error: "",
            modal: false,
            isClicked: false
    } as ApsState
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    } as ApsState);
  };

  handleClose = () => {
    this.setState({
      modal: !this.state.modal,
    } as ApsState);
  };

  handleCalculationFinished() {
    this.setState({ isClicked: false });
  }

  render() {
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="text-white"
          onClick={this.toggle}
        >
          Analytical Performance Specification
        </Button>

        <Modal
          show={this.state.modal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={this.handleClose}>
            <Modal.Title>
              Analytical Performance Specification Calculation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ApsCalculation
              within_data={this.props.within_data}
              between_data={this.props.between_data}
              analyte={this.props.analyte}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Aps;
