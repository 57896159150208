import React from "react";
import axios from "axios";
import { ListGroup } from "react-bootstrap";

class Diseases extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    // Set the state directly. Use props if necessary.
    this.state = {
      diseases: [],
    };
  }

  async componentDidMount() {
    if (!this.props.diseases) {
      await axios.get("/api/diseases/" + this.props.id).then((response) => {
        this.setState({ diseases: response.data.diseases });
      });
    } else {
      this.setState({ diseases: this.props.diseases });
    }
  }

  getAlternateNames = () => {
    if (this.state.diseases) {
      return this.state.diseases.map((key) => {
        return <ListGroup.Item key={key}>{key.item_type}</ListGroup.Item>;
      });
    } else {
      return <p>No Disease States Identified</p>;
    }
  };

  render() {
    return (
      <ListGroup>
        <this.getAlternateNames />
      </ListGroup>
    );
  }
}

export default Diseases;
