import React from "react";
import { Modal, Button, Container } from "react-bootstrap";
import AsymmetricRcvCalculation from "./AsymmetricRcvCalculation";
import ZValueTable from "../../../ui/ZValueTable";

class AsymmetricRcv extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleClose = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleCalculationFinished() {
    this.setState({ isClicked: false });
  }

  render() {
    return (
      <React.Fragment>
        <Button variant="primary" onClick={this.toggle}>
          RCV Calculation
        </Button>

        <Modal show={this.state.modal} fullscreen={true}>
          <Modal.Header closeButton onHide={this.handleClose}>
            <Modal.Title><h3>RCV Calculation</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <h4>Reference Change Value calculator</h4>
              <p>
                There are various formulae for estimating reference change values (RCVs). 
                The most correct RCV is delivered by use of the so-called asymmetrical RCV formula 
                (Fokkema MR., et al Clin Chem. 2006;52:1602–3), which gives different RCVs for increase and decrease.
              </p>
              <p>
                In the below, the asymmetrical formula is used for RCV calculation. 
                The CV% estimates of within-subject and analytical variation that
                are entered, are transformed to standard deviations (SDs) as part of the calculations. 
                If using the classical RCV formula (not included here), which gives symmetrical results 
                for increase and decrease, estimates in the form of CV% can be entered directly. 
                When the CVs are of a smaller magnitude (less than 5-10%), the symmetrical 
                and asymmetrical formulae will give similar results.
              </p>
              <p>
                To derive the RCVs, enter the analytical %CV from your laboratory. 
                The biological variation estimate (in %CV) is prefilled, but this
                 can be altered and results will then automatically update.
              </p>
              <p>
                This is an interactive tool, changing values will automatically
                update the calculations
              </p>
              <p>
                The tool automatically uses values of Z to calculate a one-sided 
                approach when the desired probability for change is entered. Results 
                and plotted data will therefore reflect the significance of a 
                uni-directional change. If you want to calculate a two-sided RCV, 
                you can use the following table as guidance, in which the 
                relationship between the p-values and the value of Z for one and 
                two-sided approaches is shown.
              </p>


              <p>
                The tool automatically  uses  values of Z to calculate   <a href="https://keydifferences.com/difference-between-one-tailed-and-two-tailed-test.html">a  one-sided approach</a> when the desired probability for  change is entered. Results and plotted data will therefore reflect the significance of a uni-directional change.  If you want to calculate a two-sided RCV, you can use the following table as guidance, in which the relationship between the p-values and the value of Z for one and two-sided approaches is shown.
              </p>

              <ZValueTable />
            </Container>

            <AsymmetricRcvCalculation
              analyte={this.props.analyte}
              cvi={this.props.cvi}
              cviLower={this.props.cviLower}
              cviUpper={this.props.cviUpper}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

}

export default AsymmetricRcv;
