import React from "react";
import { Scatter } from "react-chartjs-2";
import {
  Container,
  Col,
  Row,
  Card,
  Form,
  Button,
  Table,
} from "react-bootstrap";

class RcvChart extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    rcvUpData: this.props.rcvUpData,
    rcvDownData: this.props.rcvDownData,
    dataScatter: {
      labels: ["Scatter"],
      datasets: [
        {
          borderColor: "rgba(99,0,125, .2)",
          backgroundColor: "rgba(99,0,125, .5)",
          label: "RCV Up",
          data: this.props.rcvUpData,
          yAxisID: "yAxis",
        },
        {
          borderColor: "rgba(99,0,125, .2)",
          backgroundColor: "rgba(99,0,125, .5)",
          label: "RCV Down",
          data: this.props.rcvDownData,
        },
      ],
    },
    optionsScatter: {
      title: {
        display: true,
        text: "Significance of Change vs Probability",
      },
      scales: {
        xAxis: {
          // The axis for this scale is determined from the first letter of the id as `'x'`
          // It is recommended to specify `position` and / or `axis` explicitly.
          type: "linear",
          title: {
            display: true,
            text: "Fraction of differences",
          },
        },
        y: {
          title: {
            display: true,
            text: "Percentage change between TWO consecutive results",
          },
        },
      },
    },
  };

  render() {
    let probabilityOfChange95 = [];
    let probabilityOfChange97 = [];
    const patientData = [
      { x: 0.5, y: this.props.pctChange },
      { x: 1.0, y: this.props.pctChange },
    ];

    if (this.props.rcvUpData) {
      /*       probabilityOfChange95 = [
        { x: 0.95, y: this.props.rcvUpData[18].y },
        { x: 0.95, y: this.props.rcvDownData[18].y },
      ];

      probabilityOfChange97 = [
        { x: 0.975, y: this.props.rcvUpData[19].y },
        { x: 0.975, y: this.props.rcvDownData[19].y },
      ]; */
    }

    const dataScatter = {
      datasets: [
        {
          label: "RCV Increase",
          data: this.props.rcvUpData,
          borderColor: "black",
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 5,
          fill: true,
          tension: 0,
          showLine: true,
        },
        {
          label: "RCV Decrease",
          data: this.props.rcvDownData,
          borderColor: "green",
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 5,
          fill: true,
          tension: 0,
          showLine: true,
        },
        {
          label: "% Change in Subjects Measurand",
          data: patientData,
          borderColor: "red",
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 5,
          fill: false,
          tension: 0,
          showLine: true,
        },
        /*         {
          label: "95% Probability",
          data: probabilityOfChange95,
          borderColor: "blue",
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 5,
          fill: false,
          tension: 0,
          showLine: true,
        },
        {
          label: "97% Probability",
          data: probabilityOfChange97,
          borderColor: "green",
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 5,
          fill: false,
          tension: 0,
          showLine: true,
        }, */
      ],
    };

    return (
      <Container>
        <h3 className="mt-5">Probability Plot</h3>
        <p>Alternatively, the probability plot below enables assessment of the 
          significance of the percentage change between the two consecutive test 
          results calculated above. The plot is constructed using the asymmetric 
          RCV equation. The CVA and CVI entered above are set as constants with "z”
           used as a variable to calculate RCV. These RCV values are plotted 
           against the fraction of the differences (x-axis) below or above any 
           “% Change” (RCV) value (y-axis). The one-sided RCV for increase is 
           indicated by the grey line, and the RCV for decrease by the green line. 
           The patient’s percentage change between the two consecutive test results 
           (“% Change”, y-axis) is indicated by the red line. 
           The point at which the red line intersects the RCV curve enables 
           identification of a value on the x-axis that indicates the fraction 
           of the differences that would exhibit “% Changes” that are smaller 
           in magnitude than the patient’s. A p-value for the significance of
            the “% Change” can be calculated by subtracting the identified 
            x-axis value from 1 (1-x).
        </p>
        <Scatter data={dataScatter} options={this.state.optionsScatter} />
      </Container>
    );
  }
}

export default RcvChart;
