import React from "react";
import { Col, Row, Container } from 'react-bootstrap';
import large_logo from '../../../assets/images/eflm_logo_300.png'

function HomePageHeader(props) {
  return (
    <Container>
      <Row>
        <Col />
        <Col >
          <img src={large_logo} className="img-fluid" alt="" />
        </Col>
        <Col />
      </Row>
      <h2 className="h1 display-3 text-center">EFLM Biological Variation Database</h2>
    </Container>
  )
}
export default HomePageHeader;