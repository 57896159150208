import React from "react";
import { Row, Container } from "react-bootstrap";
import { MailgunMailingListAdminForm } from "../ui/MailgunMailingListAdminForm";

const MailgunAdmin = () => {
  return (
    <React.Fragment>
      <Container>
        <MailgunMailingListAdminForm />

      </Container>
    </React.Fragment>
  );
};

export default MailgunAdmin;