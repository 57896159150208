import React from "react";
import * as general from "packs/general_functions";
import { Table, Card } from "react-bootstrap";

class BIVACDatasetScoring extends React.Component {
  render() {
    return (
          <Card
            className=" mx-auto"
          >
              <Card.Title className="text-center bg-primary text-light">Scoring in Detail</Card.Title>
            <Table striped bordered>
              <thead>
                <tr>
                <th width="50%">Parameter</th>
                  <th width="50%">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1. Scale</td>
                  <td>{this.props.specification.scale}</td>
                </tr>
                <tr>
                  <td>2. Study Population</td>
                  <td>{this.props.specification.study_population}</td>
                </tr>
                <tr>
                  <td>3. Samples</td>
                  <td>{this.props.specification.samples}</td>
                </tr>
                <tr>
                  <td>4. Measurand</td>
                  <td>{this.props.specification.measurand}</td>
                </tr>
                <tr>
                  <td>5. Pre-Analytical</td>
                  <td>{this.props.specification.pre_analytical}</td>
                </tr>
                <tr>
                  <td>6. Replicate Analysis</td>
                  <td>{this.props.specification.replicate_analysis}</td>
                </tr>
                <tr>
                  <td>7. Steady State</td>
                  <td>{this.props.specification.steady_state}</td>
                </tr>
                <tr>
                  <td>8. Outlier Analysis</td>
                  <td>{this.props.specification.outlier_analysis}</td>
                </tr>
                <tr>
                  <td>9. Normality</td>
                  <td>{this.props.specification.normality}</td>
                </tr>
                <tr>
                  <td>10. Variance Homogeneity</td>
                  <td>{this.props.specification.variance_homogeneity}</td>
                </tr>
                <tr>
                  <td>11. ANOVA</td>
                  <td>{this.props.specification.anova}</td>
                </tr>
                <tr>
                  <td>12. CI</td>
                  <td>{this.props.specification.ci}</td>
                </tr>
                <tr>
                  <td>13. No of Results</td>
                  <td>{this.props.specification.number_of_results}</td>
                </tr>
                <tr>
                  <td>14. Concentrations</td>
                  <td>{this.props.specification.concentrations}</td>
                </tr>
                <tr>
                  <td>15. Total Score</td>
                  <td>
                    {" "}

                      <div
                        dangerouslySetInnerHTML={{
                          __html: general.displayTotalScore(
                            this.props.specification.total_score
                          ),
                        }}
                      />

                  </td>
                </tr>
                <tr>
                  <td>Comment</td>
                  <td>{this.props.specification.scoring_comment}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
    );
  }
}

export default BIVACDatasetScoring;
