import * as React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import axios from "axios";
import { ReferenceDataStructure, ReferenceResponses } from "../../types/api_responses/ApiReferences"; 
import IsLoading from "../ui/IsLoading";
import Reference from "../Reference/Reference";
import ReferenceUsedIn from "../Reference/ReferenceUsedIn";
import User from "../../types/models/User";
import Table from "react-bootstrap/esm/Table";
import Pagination from "../../types/api_responses/Pagination";
import { Button } from "react-bootstrap";
import ReferenceNoData from "../Reference/ReferenceNoData";

type ReferencesTableProps = {
  current_user: User,
  user_roles: [],
  pages : {}
};

interface ReferencesTable extends ReferenceDataStructure {
  user: User;
}

const columnHelper = createColumnHelper<ReferencesTable>();



  const renderReferences = (items: ReferenceResponses[], user: User) => {
    return <ReferenceUsedIn bv_specifications={items} user={user} />;
  };

function ReferencesTable2({
  current_user, user_roles, pages
}: ReferencesTableProps) {
  const [data, setData] = useState<ReferencesTable[]>([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(current_user);
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setnoOfPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      await axios
        .get("/api/references/?page=" + currentPage)
        .then((response) => {
          if (response.data.code == "success") {
            setData(response.data.data);
            console.log(data);
            setPagination(response.data.meta.pagination);
            setLoading(false);
          }
        });
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(function (error) {
        console.log(error);
      });
  }, [currentPage]);

  const columns = [
    columnHelper.accessor("reference", {
      header: "Reference",
      cell: (info) => (
        <ReferenceNoData reference={info.getValue()} user={user} />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("specifications", {
      header: "Used In",
      cell: (info) =>
        renderReferences(info.getValue(), info.table.options.meta?.user),
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    manualPagination: false, //turn off client-side pagination
    getCoreRowModel: getCoreRowModel(),
    meta: {
      user,
    },
  });

  // Onclick handlers for the butons
  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

  // Calculating max number of pages
  console.log(table.getRowModel().rows); // array of rows
  console.log(table.getRowModel().flatRows); // array of rows, but all sub-rows are flattened into the top level
  console.log(table.getRowModel().rowsById["row-id"]); // object of rows, where each row is keyed by its `id`

  // Creating an array with length equal to no.of pages
  const pagesArr = [...new Array(pagination?.pages)];

  const displayTable = (
    <div className="p-2">
      <div>Total Number of items {pagination?.count}</div>
      <div>Number of items displayed {pagination?.items}</div>
      <div>Current page {pagination?.from}</div>
      <div>Next Page {pagination?.next}</div>
      <div>Previous Page {pagination?.prev}</div>
      <button onClick={onPrevPage}>&#8249;</button>
      <button onClick={onNextPage}>&#8250;</button>

      {pagesArr.map((num, index) => (
        <button
          onClick={() => onPageSelect(index + 1)}
          className={`${pages}  ${
            index + 1 === currentPage ? "styles.activeBtn" : ""
          }`}
        >
          {index + 1}
        </button>
      ))}

      <Table striped bordered hover>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getCoreRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getAllCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>

      <div className="h-4" />
      <Button
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {"<<"}
      </Button>
      <Button
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {"<"}
      </Button>
      <Button
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {">"}
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      {loading ? <IsLoading IsLoading={loading} /> : displayTable}
    </React.Fragment>
  );
}

export default ReferencesTable2;
