	export function calculateAPS(cvi, cvg) {
	    const minimumCV = 0.75 * cvi;
	    const minimumBias = 0.375 * Math.sqrt(Math.pow(cvi, 2) + Math.pow(cvg, 2));
			const minimumMAU = 0.75 * 2 * cvi;

	    const desirableCV = 0.5 * cvi;
	    const desirableBias = 0.250 * Math.sqrt(Math.pow(cvi, 2) + Math.pow(cvg, 2));
			const desirableMAU = 0.5 * 2 * cvi;

	    const optimumCV = 0.25 * cvi;
	    const optimumBias = 0.125 * Math.sqrt(Math.pow(cvi, 2) + Math.pow(cvg, 2));
			const optimumMAU = 0.25 * 2 * cvi; 

	    const minimumTE = minimumBias + 1.65 * minimumCV;
	    const desirableTE = desirableBias + 1.65 * desirableCV;
	    const optimumTE = optimumBias + 1.65 * optimumCV;

	    return {
	        minimumCV: minimumCV.toFixed(1),
	        minimumBias: minimumBias.toFixed(1),
					minimumMAU: minimumMAU.toFixed(1),
	        desirableCV: desirableCV.toFixed(1),
	        desirableBias: desirableBias.toFixed(1),
					desirableMAU: desirableMAU.toFixed(1),
	        optimumCV: optimumCV.toFixed(1),
	        optimumBias: optimumBias.toFixed(1),
					optimumMAU: optimumMAU.toFixed(1),
	        minimumTE: minimumTE.toFixed(1),
	        desirableTE: desirableTE.toFixed(1),
	        optimumTE: optimumTE.toFixed(1)
	    }
	}