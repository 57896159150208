import React from "react";
import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import DeleteConfirmation from "./DeleteConfirmation";
import axios from "axios";
import { Reference } from "../../types/models/Reference";
import User from "../../types/models/User";

type EditReferenceButtonpropsType = {
  reference : Reference
  user: User
}

function EditReferenceButton({ reference }: EditReferenceButtonpropsType) {
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [type, setType] = useState("");
  const [id, setId] = useState<number>();
  const [deleteMessage, setDeleteMessage] = useState("");
  const [status, setStatus] = useState("");

  const edit_url = "/references/" + reference.id + "/edit";

  // Handle the displaying of the modal based on type and id
  const showDeleteModal = (type: string , id: number) => {
    setType(type);
    setId(id);
    setDeleteMessage(`Are you sure you want to delete the reference?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    axios
      .delete("api/references/" + reference.id)
      .then((response) => setStatus("Delete successful"))
      .catch((error) => {
        setErrorMessage(error.message);
        console.error("There was an error!", error);
      });

    setDisplayConfirmationModal(false);
  };

  return (
    <div>
      <ButtonGroup>
        <Button href={edit_url} size="sm">
          Edit{" "}
        </Button>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => showDeleteModal("reference", reference.id)}
        >
          Delete
        </Button>
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
          type={type}
          id={id}
          message={deleteMessage}
        />
      </ButtonGroup>
    </div>
  );
}

export default EditReferenceButton;


function setErrorMessage(message: any) {
  throw new Error("Function not implemented.");
}

