import React from "react";
import User from "../../types/models/User";
import { ButtonGroup, Table } from "react-bootstrap";
import BvSpecification from "../../types/models/BvSpecification";
import BIVACTools from "../ui/BIVACTools";
import IncludeInMeta from "../ui/IncludeInMetaAnalysis";
import ViewSpecificationButton from "../ui/ViewSpecificationButton";
import IsReviewed  from "../ui/IsReviewed";
import { Reference } from "../../types/models/Reference";
import IsPublished from "../ui/IsPublished";

type TableOfReferencesProps = {
  user: User;
  specifications: Array<BvSpecification>;
  reference?: Reference,
  export_url: string
};

function TableOfReferences({ user, specifications, reference, export_url }: TableOfReferencesProps) {

  const SpecsTable = (): JSX.Element => {
    
    
    return (
      <>
        <Table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Analyte</td>
              <td>Included in Meta</td>
              <td>Reviewed</td>
              <td>Published</td>
              <td>Score</td>
              <td>Tools</td>
            </tr>
          </thead>
          <tbody>
            {specifications.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.analyte.display_name}</td>
                  <td>
                    <IncludeInMeta specification={item} />
                  </td>
                  <td><IsReviewed reviewed={item.review}></IsReviewed></td>
                  <td><IsPublished published={item.published}></IsPublished></td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.score_display! }}
                    />
                  </td>
                  <td>
                    <ButtonGroup vertical>
                      <BIVACTools specification={item} user={user} />
                      <ViewSpecificationButton specification={item} />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <a target="_blank" href={export_url} className="btn btn-success">
          Export
        </a>
      </>
    );
  };

  return (
    <>
      {specifications.length ? (
        <SpecsTable />
      ) : (
        "No References"
      )}

    </>
  );
}

export default TableOfReferences;
