import Analyte from "../../types/models/Analyte";
import Matrix from "../../types/models/Matrix";
import MetaAnalysis from "../../types/models/MetaAnalysis";

function createDummyMetaAnalysis(
  meta_results: MetaAnalysisResult,
  reference_ids: Array<number>,
  type: string,
  matrix: Matrix,
  analyte: Analyte
) : MetaAnalysis {
  const dummy: MetaAnalysis = {
    id: 100,
    analyte_id: analyte.id,
    comments: "",
    display: true,
    lower: meta_results.Range_lower,
    matrix: matrix,
    matrix_id: matrix.id,
    median: meta_results["W.Median"],
    number_used: meta_results.N,
    references_array: reference_ids,
    upper: meta_results?.Range_upper,
    var_type: type,
  };

  return dummy;
}

export default createDummyMetaAnalysis;