import React from "react";
import {Badge} from 'react-bootstrap';

function IsPublished(props: { published: boolean; }) {
  if (props.published == true) {
    return <div><Badge bg="success">Published on Website</Badge></div>;
  } else {
    return <div><Badge bg="warning">Not Published on Website</Badge></div>;
  }
}

export default IsPublished;
