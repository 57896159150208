export function displayTotalScore(score_json) {
    const newJson = stringToJson(score_json)

    var sortedKeys = Object.keys(newJson).sort().reverse()

    return (buildScore(sortedKeys, newJson))
}

export function stringToJson(jsonString) {
    const str = jsonString.replace(/=>/g, ':');
    return JSON.parse(str)
}

export function buildScore(sortedKeys, newJson) {
    for (var i = 0; i < sortedKeys.length; i++) {
        const value = newJson[sortedKeys[i]]
        if (value != "") {
            const htmlString = `${sortedKeys[i]}<sub>${value}</sub>`
            return htmlString
        }
    }
}