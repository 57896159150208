import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { Matrix } from "../../types/models/Matrix";
import User from "../../types/models/User";

type MatricesProps = {
  matrices: Array<Matrix>;
  user_id: User;
  user_roles: any;
};

function Matrices(matrices: MatricesProps) {
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "matrix_expansion",
      text: "Matrix Expansion",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "matrix_explanation",
      text: "Matrix Explanation",
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      formatter: (cellContent, row) => {
        return moment(row.updated_at).format("MMMM Do YYYY");
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <BootstrapTable
      keyField="id"
      data={matrices.matrices}
      columns={columns}
      defaultSorted={defaultSorted}
      filter={ filterFactory() }
      striped
      hover
      condensed
    />
  );
}

export default Matrices;
