import React, { useState, useEffect } from "react";
import axios from "axios";

function AppStatus(props) {

  const [restData, setRestData] = useState([]);
  const [errorData, setErrorData] = useState([]);

  useEffect(() => {
    axios.get(props.rrest, {
      params: {
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    })
      .then(function (response) {
        setRestData(response)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

        } else {
          // Something happened in setting up the request that triggered an Error

        }

        setErrorData(error)
      })
  }, []);


  let badge;

  if (restData.data == "OK") {
    badge = <span className="badge text-bg-success bg-success">OK</span>;
  } else {
    badge = <span className="badge text-bg-danger bg-danger">FAULT</span>;
  }

  return (
    <>

      <div className="card" style={{ width: 18 + 'rem' }}>
        <div className="card-body">
          <h5 className="card-title">Rest Server Status</h5>
          <h6 className="card-subtitle mb-2 text-muted">Tests to see if the R server is responding</h6>
          <p className="card-text">{badge}</p>
          <p className="card-text">Tested URL</p>
          <p className="card-text"> {errorData.message ? <code className="bg-danger">{errorData.message} on {props.rrest}</code> : <code className="bg-success">{props.rrest}</code>}
          </p>
        </div>
      </div>
    </>
  );

};



export default AppStatus;
