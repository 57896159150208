import React from "react";
import { Container } from 'react-bootstrap';
import GetPage from "../Services/GetPage";

const baseURL = "https://cms.biologicalvariation.eu/wp-json/wp/v2/pages/25";

function EflmWg() {
  return (
    <Container className='w-95'>
      <GetPage url={baseURL} />
    </Container>
  )
}

export default EflmWg;