import React from "react";
import BIVACTools from "../ui/BIVACTools";
import moment from "moment";
import { Table } from "react-bootstrap";
import IncludeInMeta from "../ui/IncludeInMetaAnalysis";
import User from "../../types/models/User";
import BvSpecification from "../../types/models/BvSpecification";

type SpecificationTableProps = {
  user: User;
  specs: Array<BvSpecification>;
};

function SpecificationTable({ user, specs }: SpecificationTableProps){

let table = [];

    for (let specification of specs) {
        table.push(
          <tr key={specification.id}>
            <td>{specification.id}</td>
            <td>{specification.analyte.display_name}</td>
            <td id={specification.reference.id.toString()}>
              {specification.reference.id}
            </td>
            <td>
              <IncludeInMeta specification={specification} />
            </td>
            <td>{moment(specification.updated_at).format("MMMM Do YYYY")}</td>
            <td>
              <BIVACTools specification={specification} user={user} />
            </td>
          </tr>
        );
    }     

  return (
    <Table striped bordered hover id="specs_table" className="dataTable">
      <thead>
        <tr role="row">
          <th>ID</th>
          <th>Measurand</th>
          <th>Reference ID</th>
          <th>Meta Analysis</th>
          <th>Date Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody role="alert" aria-live="polite" aria-relevant="all">
        {table}
      </tbody>
    </Table>
    );
};



export default SpecificationTable;
