import React, { useEffect, useState } from "react";
import axios from "axios";
import { Accordion, Container } from "react-bootstrap";
import { GridLoader } from "react-spinners";
import MetaCalculationsSummaryTableTyped from "../MetaCalculation/MetaCalculationsSummaryTableTyped";
import extractVariation from "../Services/extractVaration";
import User from "../../types/models/User";
import MetaByAnalyteApiResponse from "../../types/api_responses/MetaByAnalyte";
import ReferencesForMetaAnalysisTyped from "../Reference/ReferencesForMetaAnalysisTyped";
import MetaAnalysis from "../../types/models/MetaAnalysis";
import Analyte from "../../types/models/Analyte";

type MetaCalculationsIndex = {
  user: User;
  user_Roles: Array<any>;
};

type MetaCalculationsIndexProps = {
  analyte_id: number;
  references_used_in_metanalysis?: number[][];
  user: User;
};

function MetaCalculationsIndex(MetaCalculationsIndexProps: any) {
  const [metacalculations, setMetaCalculations] = useState<
    MetaByAnalyteApiResponse | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const user = MetaCalculationsIndexProps.user;

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      await axios.get("/api/meta_calculations/").then((response) => {
        if (response.data.code == "success") {
          setMetaCalculations(response.data);
          setLoading(false);
        }
      });
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const renderTableData = () => {
    let tables: JSX.Element[] = [];

    metacalculations?.data.forEach(
      (meta: { metas: MetaAnalysis[]; analyte: Analyte }) => {
        const data = extractVariation(meta.metas);
        const references = [
          data.between_data.references_array,
          data.within_data.references_array,
        ];

        tables.push(
          <MetaCalculationsSummaryTableTyped
            analyte={meta.analyte}
            key={meta.analyte.id}
            within_data={data.within_data}
            between_data={data.between_data}
            matrix={data.matrix}
          />
        );

        tables.push(
          <><Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                References Used in Metacalculation
              </Accordion.Header>
              <Accordion.Body>
                <ReferencesForMetaAnalysisTyped
                  analyte_id={meta.analyte.id}
                  user={user}
                  references_used_in_metanalysis={references} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion><hr /></>
        );
      }
    );
    return tables;
  };

  return (
    <>
      <Container>
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <GridLoader size={15} color={"#123abc"} loading={loading} />
            </div>
          </>
        ) : (
          renderTableData()
        )}
      </Container>
    </>
  );
}

export default MetaCalculationsIndex;
