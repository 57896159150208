import React from "react";
import {Badge} from 'react-bootstrap';

function IsReviewed(props: { reviewed: boolean; }) {
  if (props.reviewed == true) {
    return <div><Badge bg="success">Reviewed</Badge></div>;
  } else {
    return <div><Badge bg="warning">Not Reviewed</Badge></div>;
  }
}

export default IsReviewed;
