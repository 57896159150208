// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
//require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import 'core-js/stable'
import ('@fortawesome/fontawesome-free/css/all.css');
import "../stylesheets/application";
import "select2/dist/js/select2.full.min.js";
import "./searchable_select/init.js";


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
