import React, { useState, useEffect } from "react";
import useInterval from "../Services/useInterval";
import { Table, ProgressBar } from "react-bootstrap";
import GetJob from "../Services/GetJob";

function JobTracker(props) {
  const [job, setJob] = useState({});
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const [delay, setDelay] = useState(1000);
  const [isRunning, setIsRunning] = useState(true);
  const [progress, setProgress] = useState(0);
  const [response, setResponse] = useState({});

  useEffect(() => {
    getData(props)
  }, []);

  function getData(props) {
    GetJob(props.jobId)
      .then(function (response) {
        setResponse(response)

        if (response.status == 200) {
          setJob(response.data)
        } else {
          setIsRunning(false)
          setProgress(100)
          setError(true)
        }
      })
  };

  useInterval(() => {
    // Your custom logic here
    setCount(count + 1);
    setProgress((job.progress_current / job.progress_max * 100).toFixed(0))

    if (job.status == "complete") {
      setIsRunning(false)
      setProgress(100)
    }
  }, isRunning ? delay : null);

  const resultsTable = (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Name: {count}</td>
          <td>{job.name}</td>
        </tr>
        <tr>
          <td>Job id:</td>
          <td>{job.id}</td>
        </tr>
        <tr>
          <td>Sidkiq id:</td>
          <td>{job.jid}</td>
        </tr>
        <tr>
          <td>Queue:</td>
          <td>{job.queue}</td>
        </tr>
        <tr>
          <td>Classname:</td>
          <td>{job.class_name}</td>
        </tr>
        <tr>
          <td>Started At:</td>
          <td>{job.started_at}</td>
        </tr>
        <tr>
          <td>Finished At:</td>
          <td>{job.finished_at}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{job.status}</td>
        </tr>
        <tr>
          <td>Progress Stage:</td>
          <td>{job.progress_stage}</td>
        </tr>
        <tr>
          <td>Progress Current:</td>
          <td>{job.progress_current}</td>
        </tr>
        <tr>
          <td>Progress Max:</td>
          <td>{job.progress_max}</td>
        </tr>
        <tr>
          <td>Progress</td>
          <td>
            <ProgressBar now={progress} label={`${(progress)}%`} /></td>
        </tr>
      </tbody>
    </Table>
  )

  return (
    <div>
      {error
        ? response.data.error
        : resultsTable
      }
    </div>
  );
}


export default JobTracker;