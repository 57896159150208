function extractArraysFromMetaAnalysis(reference_array:number[][]) : number[]{
  let result = reference_array!.map((a) => {
    return a;
  });

  let result2 = result.map((a) => a.map((b) => parseInt(b)));

  var x: number[] = [];
  var merged = x.concat.apply([], result2);
  let uniqueItems = [...new Set(merged)];
  return uniqueItems
}

export default extractArraysFromMetaAnalysis;