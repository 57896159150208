import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import TrackAJob from "../ui/TrackAJob"

function JobTracker(props) {

  return (
    <Container>
      <Row>
        <Col>
        <h2>Calculating Consolidated Metas</h2>
          <TrackAJob jobId={props.consolidated_job} />
        </Col>
        <Col>
        <h2>Calculating Meta-Analysis</h2>
          <TrackAJob jobId={props.meta_job} />
        </Col>
      </Row>
    </Container>
  );
}


export default JobTracker;
