import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

type VariationTypeProps = {
  vartype?: string;
};

const renderIntra = () => (
  <Tooltip id="button-tooltip" >
    Within-subject variation
  </Tooltip>
)

const renderInter = () => (
  <Tooltip id="button-tooltip">
    Between-subject variation
  </Tooltip>
)

const Intra = () => (
  <FontAwesomeIcon icon={faUserAlt} />
)

const Inter = () => (
  <FontAwesomeIcon icon={faUsers} />
)


function VariationType({ vartype }: VariationTypeProps) : JSX.Element{
  if (vartype == ":cvi") {
    return (
      <OverlayTrigger placement="right" overlay={renderIntra}>
        <b>
          <Intra />
        </b>
      </OverlayTrigger>
    );
  }

  if (vartype == ":cvg") {
    return (
      <OverlayTrigger placement="right" overlay={renderInter}>
        <b>
          <Inter />
        </b>
      </OverlayTrigger>
    );
  }

  return <div>NA</div>
}

export default VariationType;
