import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReferenceUsedIn from "../Reference/ReferenceUsedIn";
import ReferenceNoData from "../Reference/ReferenceNoData";
import { GridLoader } from "react-spinners";
import IsLoading from "../ui/IsLoading";
import filterFactory, {
  textFilter,
  Comparator,
} from "react-bootstrap-table2-filter";

// ...

const RemoteAll = ({
  columns,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
}) => (
  <div>
    <BootstrapTable
      remote
      keyField="id"
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      filter={filterFactory()}
      filterPosition="top"
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
    />
  </div>
);

RemoteAll.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

const defaultSorted = [
  {
    dataField: "name",
    order: "desc",
  },
];

class ReferencesTable extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    // Set the state directly. Use props if necessary.
    this.state = {
      loading: true,
      references: [],
      page: 1,
      sizePerPage: 10,
      data: [],
      totalSize: 0,
    };
  }

  columns = [
    {
      dataField: "title",
      text: "Reference",
      // headerStyle: { width: "30%" },
      sort: true,
      formatter: (cellContent, row) => {
        return <ReferenceNoData reference={row.reference} user={this.props.user_id} />;
      },
      headerClasses: "bg-secondary",
      filter: textFilter({
        defaultValue: "",
        placeholder: "Search for Reference",
      }),
    },
    {
      dataField: "specifications",
      text: "Used In",
      // headerStyle: { width: "50%" },
      headerClasses: "bg-secondary",
      formatter: (cellContent, row) => {
        return (
          <ReferenceUsedIn reference_id={row.reference.id} bv_specifications= {row.specifications} user={this.props.user_id} />
        );
      },
    },
  ];

  async componentDidMount() {
    if (!this.props.reference) {
      await axios.get("/api/references/").then((response) => {
        if (response.data.code == "success") {
          this.setState({
            references: response.data.data,
            data: response.data.data.slice(0, 0 + 10),
            totalSize: response.data.data.length,
            loading: false,
          });
        }
      });
    } else {
      this.setState({ references: this.props.references });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;

    let result = this.state.references;

    // Handle column filters
    result = result.filter((row) => {
      let valid = true;
      for (const dataField in filters) {
        const { filterVal, filterType, comparator } = filters[dataField];

        if (filterType === "TEXT") {
          if (comparator === Comparator.LIKE) {
            const title = row['reference']['title'].toString()
            const authors = row['reference']['author'].toString()
            const id = row['reference']['id'].toString()
            const link_to_spanish_database = row['reference']['link_to_spanish_database']
            const intermediate = title.concat(authors)

            valid = intermediate.concat(link_to_spanish_database).concat(id).toLowerCase().indexOf(filterVal.toLowerCase()) > -1;
            
          } else {
            valid = row[dataField] === filterVal;
          }
        }
        if (!valid) break;
      }
      return valid;
    });

    // Handle column sort
    if (sortOrder === "asc") {
      result = result.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = result.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }

    this.setState(() => ({
      page,
      data: result.slice(currentIndex, currentIndex + sizePerPage),
      totalSize: result.length,
      sizePerPage,
    }));
  };

  render() {
    const { data, page, sizePerPage } = this.state;

    return (
      <React.Fragment>
        <IsLoading IsLoading={this.state.loading} />
        <div className="d-flex justify-content-center">
          <GridLoader size={15} color={"#123abc"} loading={this.state.loading}>
            <span className="visually-hidden">Loading...</span>
          </GridLoader>
        </div>
        <div>Total Number of References Found: {this.state.totalSize}</div>

        <RemoteAll
          columns={this.columns}
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={this.state.totalSize}
          onTableChange={this.handleTableChange}
        />
      </React.Fragment>
    );
  }
}

export default ReferencesTable;
