import $ from 'jquery';
import { calculateRCV } from './calculateRCV';

$(document).ready(function(){
	$("#main_body").on('click', '#RCV', function(e) {

		const id = e.target.id.split("_")[0];

		var cvi = $("#cvi_meta_median_" + id).text();

		const url = '/rcv/' ;

		$.ajax({
			type: "POST",
			url: url,
			data: { cvi: cvi,},
			success: function(data) {
				document.getElementById("answer").style.display = "none";

				calculateRCV(data)
			},

			error: function(XMLHttpRequest, textStatus, errorThrown) {
				alert("Status: " + textStatus); alert("Error: " + errorThrown);
			},
		});

	});

});