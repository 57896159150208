import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Specifications from "./Specifications";
import Analyte from "./analyte/Analyte";
import { Container, Card, Row, CardGroup, Col } from 'react-bootstrap';

class SpecificationList extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    datasets: [],
    isLoaded: false,
  };

  handleChange(e) {
    this.setState({ isLoaded: true }, () => this.props.onLoading(true));
  }

  async componentDidMount() {
    await axios.get("/api/bv_specifications/by_measurand").then((response) => {
      const datasets = response.data.datasets;
      this.setState({ datasets }, () => this.handleChange());
    });
  }

  renderAllSpecsForAnalyte = (specifications, analyte_name) => {
    return (
      <Card>
        <Card.Body>
          <Card.Title>{analyte_name}</Card.Title>
          <Card.Text>There are: {specifications.length} Records</Card.Text>
          <Specifications
            specifications={specifications}
            analyte_name={analyte_name}
          />
        </Card.Body>
      </Card>
    );
  };

  render() {
    let columns = [];

    for (const [idx, [key, analyte_dataset]] of Object.entries(Object.entries(this.state.datasets))) {
      columns.push(
        <Col id={key} key={idx}>
          {this.renderAllSpecsForAnalyte(analyte_dataset, key)}
        </Col>
      );
    }

    return (
      <Row xs={2} md={3} lg={3}>
        {columns}
      </Row>
    )
  }
}

SpecificationList.propTypes = {
  title: PropTypes.string,
  published: PropTypes.bool,
};

export default SpecificationList;
