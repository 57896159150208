import React from "react";
import { ListGroup } from "react-bootstrap";
import axios from "axios";

function FullName(fullname) {
  if (fullname.fullname != undefined) {
    return (
      <strong></strong>
    );
  } else {
    return (
      <strong> No Full Name Set </strong>
    );
  }
};
class Analyte extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    // Set the state directly. Use props if necessary.
    this.state = {
      analyte: [],
      alternate_names: [{ 0: "No Alternative" }],
    };
  }

  async componentDidMount() {
    if (this.props.analyte) {
      this.setState({ analyte: this.props.analyte });
      return;
    }
    await axios.get("/api/analytes/" + this.props.id).then((response) => {
      this.setState({ analyte: response.data.analyte });

      if (response.data.analyte.alternate_names) {
        try {
          var json = JSON.parse(
            response.data.analyte.alternate_names.replace(/'/g, '"')
          );
          if ((json = {})) {
            return;
          }
          this.setState({ alternate_names: json });
        } catch (e) {
          var json = response.data.analyte.alternate_names
            .replace(/,$/, "")
            .split(",");
          this.setState({ alternate_names: json });
        }
      }
    });
  }

  getAlternateNames = () => {
    const data = Array.from(this.state.alternate_names);

    var nameNodes = data.map((name) => {
      return <ListGroup.Item key="{name[1]}">{name[0]}</ListGroup.Item>;
    });

    return <ListGroup>{nameNodes}</ListGroup>;
  };



  AnalyteDetails = () => {
    if (this.props.displayFull) {
      return (
        <div>
          <h6>Display Name: <strong>{this.state.analyte.display_name}</strong></h6>
          <h6>Full Name: <FullName fullname={this.state.full_name} /></h6>
          <h6>Alternate Names</h6>
          {this.getAlternateNames()}
        </div>
      );
    }
    return <b> {this.state.analyte.display_name} </b>;
  };

  render() {
    return <React.Fragment>{this.AnalyteDetails()}</React.Fragment>;
  }
}

Analyte.defaultProps = {
  display_name: "Analyte Loading",
  id: "1",
};

export default Analyte;
