import React from "react";
import axios from "axios";

function GetPage(baseURL) {
  const [page, setPage] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL.url).then((response) => {
      setPage(response.data);
    });
  }, []);

  if (!page) return null;

  return (
    <div>
      <h1>{page.title.rendered}</h1>
      <div dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
    </div>
  );
}

export default GetPage;