import React from "react";
import { Container } from "react-bootstrap";
import { ClockLoader } from "react-spinners";

type IsLoadingProps = {
  IsLoading: Boolean;
};

function IsLoading({
  IsLoading
}: IsLoadingProps) {
  if (IsLoading == true) {
    return (
      <Container>
        <div className="d-flex justify-content-center">
        <p className="text-success"><ClockLoader/></p>
        </div>
      </Container>

    )
  } else {
    return (
      <React.Fragment></React.Fragment>
    );
  }
}

export default IsLoading;
