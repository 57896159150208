import React, { ChangeEventHandler, useEffect, useState } from "react";
import IsLoading from "../ui/IsLoading";
import User from "../../types/models/User";
import axios from "axios";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Pagination from "../../types/api_responses/Pagination";
import BvSpecification from "../../types/models/BvSpecification";
import Analyte from "../../types/models/Analyte";
import { Button, Form, Table } from "react-bootstrap";
import Specifications from "../BIVAC/Specifications";

type AllBVSpecificationsProps = {
  current_user: User;
};

type DataStructure = {
  entry: { analyte: Analyte; specifications: ByMeasurandApiResponse[] };
};

interface ByMeasurandApiResponse extends DataStructure {
  bv_specification: BvSpecification;
}

function renderSpecifications(specifications: ByMeasurandApiResponse[]) {
  var bviacArray = specifications.map(function (el) {
    return el.bv_specification;
  });
  return <Specifications specifications={bviacArray} />;
}

function renderAnalyte(analyte: Analyte) {
  return analyte;
}

function AllBVSpecifications({ current_user }: AllBVSpecificationsProps) {
  const [data, setData] = useState<DataStructure[]>([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(current_user);
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setnoOfPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      await axios
        .get("/api/bv_specifications/by_measurand?page=" + currentPage)
        .then((response) => {
          if (response.status == 200) {
            setData(response.data.data);
            setPagination(response.data.meta.pagination);
            setnoOfPages(response.data.meta.pagination.pages);
            setLoading(false);
          }
        });
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(function (error) {
        console.log(error);
      });
  }, [currentPage]);

  // Creating an array with length equal to no.of pages
  const pagesArr = [...new Array(noOfPages)];

  const columnHelper = createColumnHelper<DataStructure>();

  const columns = [
    columnHelper.accessor("entry.analyte", {
      header: "Analyte",
      cell: (info) => renderAnalyte(info.getValue()),
    }),
    columnHelper.accessor("entry.specifications", {
      header: "Dataset Derived From",
      cell: (info) => renderSpecifications(info.getValue()),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    manualPagination: false, //turn off client-side pagination
    rowCount: pagination?.pages,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      user,
    },
  });

  // Onclick handlers for the butons
  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevPage = () => setCurrentPage(currentPage - 1);
  
  function PageNumbers(pagesArr: Array<number>) {
    return (
      <Form.Select onChange={(e) => setCurrentPage(Number(e.target.value))}>
        <option>Select Page Number</option>
        {pagesArr.map((num: number, index: number) => (
          <option value={index + 1}>{index + 1}</option>
        ))}
      </Form.Select>
    );
  }

  function displayTable() {
    return (
      <>
        <div>Total Number of items {pagination?.count}</div>
        <div>Number of pages {pagination?.pages}</div>
        <div>Current page {pagination?.page}</div>
        <div>Next Page {pagination?.next}</div>
        <div>Previous Page {pagination?.prev}</div>
        {currentPage > 1 ? <Button variant="primary" onClick={onPrevPage}>&#8249;</Button> : ""}
        {currentPage < noOfPages ? (
          <Button variant="primary" onClick={onNextPage}>&#8250;</Button>
        ) : (
          ""
        )}
        {PageNumbers(pagesArr)}

        <div className="p-2">
          <Table striped bordered hover>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getCoreRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getAllCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </Table>
          <div className="h-4" />
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      {loading ? <IsLoading IsLoading={loading} /> : displayTable()}
    </React.Fragment>
  );
}

export default AllBVSpecifications;
