import React from 'react'
import { Table } from "react-bootstrap";

const ZValueTable = () => {
  return (

    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Z</th>
          <th>p Value 1 sided</th>
          <th>p Value 2 sided</th>
        </tr>
      </thead>
      <tbody>
        <tr className="font-monospace">
          <td>1.64</td>
          <td>0.05</td>
          <td>0.10</td>
        </tr>
        <tr className="font-monospace">
          <td>1.96</td>
          <td>0.025</td>
          <td>0.05</td>
        </tr>
        <tr className="font-monospace">
          <td>2.32</td>
          <td>0.01</td>
          <td>0.025</td>
        </tr>
        <tr className="font-monospace">
          <td>2.57</td>
          <td>0.005</td>
          <td>0.01</td>
        </tr>
        <tr className="font-monospace">
          <td>2.81</td>
          <td>0.0025</td>
          <td>0.005</td>
        </tr>
        <tr className="font-monospace">
          <td>3.09</td>
          <td>0.0001</td>
          <td>0.0002</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default ZValueTable;

