import React from "react";
import { Table } from "react-bootstrap";
import { Matrix } from "../../types/models/Matrix";
import Analyte from "../../types/models/Analyte";
import MetaAnalysis from "../../types/models/MetaAnalysis";
import MetaCalculationsRowsPartialTyped from "./MetaCalculationRowPartialTyped";
import MetaCalculationsTools from "../ui/MetaCalculationsTools";


type MetaCalculationsSummaryTableTypedProps = {
  analyte: Analyte;
  within_data?: MetaAnalysis;
  between_data?: MetaAnalysis;
  matrix: Matrix
};

function MetaCalculationsSummaryTableTyped({
  analyte,
  within_data,
  between_data,
  matrix
}: MetaCalculationsSummaryTableTypedProps) {

  let table: Array<any> = [];

  table.push(
    <Table bordered hover key={"table" + analyte.display_name + analyte.id} responsive>
      <thead>
        <tr key={analyte.display_name + "_row"}>
          <td colSpan={7} className="text-black bg-light display-6">
            {analyte.display_name}

            <div className="d-grid gap-2 d-md-block">
              <MetaCalculationsTools
                within_data={within_data}
                between_data={between_data}
                analyte={analyte}
              />
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr role="row" key={"header_" + analyte.id}>
          <th>Matrix</th>
          <th>BV Estimate</th>
          <th>median CV estimate</th>
          <th>lower CI limit</th>
          <th>higher CI limit</th>
          <th>Comments</th>
          <th>Date Updated</th>
        </tr>
        <MetaCalculationsRowsPartialTyped
          within_data={within_data}
          between_data={between_data}
          matrix={matrix}
        />
      </tbody>
    </Table>
  );

  return <React.Fragment>{table}</React.Fragment>;
}

export default MetaCalculationsSummaryTableTyped;
