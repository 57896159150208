import React from "react";
import BvSpecification from "../../types/models/BvSpecification";

type IncludeInMetaProps = {
  specification: BvSpecification;
};

const IncludeInMeta = ({ specification }: IncludeInMetaProps) => {
  if (specification.include_in_meta_analysis == true) {
    return (
      <div>
        <span className="badge bg-success">
          <b>Included in Meta-Analysis</b>
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="badge text-dark bg-warning">
          <b>Not Included in Meta-Analysis</b>
        </span>
      </div>
    );
  }
};

export default IncludeInMeta;
