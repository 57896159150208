import axios from "axios";
import ApiMatrix from "../../types/api_responses/ApiMatrix";

async function GetMatrix(id: number) : Promise<ApiMatrix>{
  const data = await axios
    .get(`/api/matrices/${id}`)
    .then((promise: { data: any }) => {
      return promise.data;
    })
    .catch((e: any) => {
      console.error(e);
    });
  return data;
}

export default GetMatrix;
