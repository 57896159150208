import React from "react";
import Reference from "../Reference/Reference";
import { Table, OverlayTrigger, Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import * as general from "packs/general_functions";
import IncludeInMeta from "../ui/IncludeInMetaAnalysis";
import BIVACPopOver from "../ui/BIVACPopOver";
import BvSpecification from "../../types/models/BvSpecification";

type SpecificationsProps = {
  specification: BvSpecification;
};

function BIVACDatasetSummary({ specification }: SpecificationsProps) {

  const popover = (
    BIVACPopOver()
  );

  return (
    <Table bordered hover>
      <thead className="bg-primary text-light">
        <tr>
          <th>Reference</th>
          <th></th>
          <th>
            Total Score
            <OverlayTrigger trigger="click" placement="auto" overlay={popover}>
              <Button size="sm" variant="success">
                Scoring explained
              </Button>
            </OverlayTrigger>
          </th>
          <th>
            Estimates of Within Subject <br />
            <small>(± calculated CI)</small>
          </th>
          <th>
            Estimates of Between Subject <br />
            <small>(± calculated CI)</small>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Reference reference={specification.reference} />
          </td>

          <td>
            <IncludeInMeta specification={specification} />
          </td>

          <td>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: general.displayTotalScore(specification.total_score),
                }}
              ></div>
            </div>
            <br />
          </td>

          <td>
            <p className="font-monospace fs-5 ">
              <strong>
                <NumberFormat
                  value={specification.estimates_of_cvi}
                  displayType={"text"}
                  decimalScale="2"
                />
              </strong>
            </p>
            <p className="font-monospace fs-6 text-break text-nowrap">
              (
              <NumberFormat
                value={specification.calculated_within_bv_lower_ci}
                displayType={"text"}
                decimalScale="2"
              />{" "}
              -{" "}
              <NumberFormat
                value={specification.calculated_within_bv_upper_ci}
                displayType={"text"}
                decimalScale="2"
              />
              )
            </p>
          </td>

          <td>
            <p className="font-monospace fs-5 ">
              <strong>
                <NumberFormat
                  value={specification.estimates_of_cvg}
                  displayType={"text"}
                />{" "}
              </strong>
            </p>
            <p className="font-monospace fs-6 text-break text-nowrap">
              (
              <NumberFormat
                value={specification.calculated_between_bv_lower_ci}
                displayType={"text"}
                decimalScale="2"
              />{" "}
              -{" "}
              <NumberFormat
                value={specification.calculated_between_bv_upper_ci}
                displayType={"text"}
                decimalScale="2"
              />
              )
            </p>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default BIVACDatasetSummary;
