  export function calculateAsymmetricRCV(cvi, cva, zvalue) {
      const SDI2 = calcSDI(cvi);
      const SDA2 = calcSDI(cva);

      const combinedLog = calcCombinedLog(SDI2, SDA2);
      const plusZalpha = calcZalpha(zvalue, combinedLog)
      const minusZalpha = calcZalpha(-zvalue, combinedLog)

      const rcvUp = calcRcv(plusZalpha);
      const rcvDown = calcRcv(minusZalpha);

      return ({
          rcvUp: rcvUp,
          rcvDown: rcvDown,
      });
  }


  export function modelAsymmetricRCV(cvi, cva) {
      // generate a range of probabilities from 0.99 -> 0.50 in 0.05 steps
      const probabilities = myrange(0.5, 1.0, 0.025);
      const zNumbers = probabilities.map(x => NormSInv(x));
      const graphData = zNumbers.map(x => calculateAsymmetricRCV(cvi, cva, x));

      const rcvUpDataPoints = [];
      const rcvDownDataPoints = [];

      graphData.forEach(function(value, i) {
          rcvUpDataPoints.push({
              x: probabilities[i],
              y: value.rcvUp
          });
          rcvDownDataPoints.push({
              x: probabilities[i],
              y: value.rcvDown
          });
      });

      return [rcvUpDataPoints, rcvDownDataPoints];
  }



  export function calcSDI(cv) {
      return Math.log((cv / 100) * (cv / 100) + 1);
  }

  export function calcCombinedLog(sdi1, sdi2) {
      return (Math.sqrt(sdi1 + sdi2));
  }

  export function calcZalpha(zvalue, combinedLog) {
      return ((zvalue * Math.SQRT2) * combinedLog);
  }

  export function calcRcv(zAlpha) {
      return (100 * (Math.pow(Math.E, zAlpha) - 1));
  }

  export const myrange = (start, stop, step = 1) =>
      Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

  export function NormSInv(p) {
      var a1 = -39.6968302866538,
          a2 = 220.946098424521,
          a3 = -275.928510446969;
      var a4 = 138.357751867269,
          a5 = -30.6647980661472,
          a6 = 2.50662827745924;
      var b1 = -54.4760987982241,
          b2 = 161.585836858041,
          b3 = -155.698979859887;
      var b4 = 66.8013118877197,
          b5 = -13.2806815528857,
          c1 = -7.78489400243029E-03;
      var c2 = -0.322396458041136,
          c3 = -2.40075827716184,
          c4 = -2.54973253934373;
      var c5 = 4.37466414146497,
          c6 = 2.93816398269878,
          d1 = 7.78469570904146E-03;
      var d2 = 0.32246712907004,
          d3 = 2.445134137143,
          d4 = 3.75440866190742;
      var p_low = 0.02425,
          p_high = 1 - p_low;
      var q, r;
      var retVal;

      if ((p < 0) || (p > 1)) {
          alert("NormSInv: Argument out of range.");
          retVal = 0;
      } else if (p < p_low) {
          q = Math.sqrt(-2 * Math.log(p));
          retVal = (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
      } else if (p <= p_high) {
          q = p - 0.5;
          r = q * q;
          retVal = (((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q / (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
      } else {
          q = Math.sqrt(-2 * Math.log(1 - p));
          retVal = -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
      }

      return retVal;
  }

  export function percentile_z(p) {
      return calc_q(p);
  }


   var Z_MAX = 6; // Maximum ±z value
   var ROUND_FLOAT = 2; // Decimal places to round numbers

   /*  The following JavaScript functions for calculating normal and
        chi-square probabilities and critical values were adapted by
        John Walker from C implementations
        written by Gary Perlman of Wang Institute, Tyngsboro, MA
        01879.  Both the original C code and this JavaScript edition
        are in the public domain.  */

   /*  POZ  --  probability of normal z value

        Adapted from a polynomial approximation in:
                Ibbetson D, Algorithm 209
                Collected Algorithms of the CACM 1963 p. 616
        Note:
                This routine has six digit accuracy, so it is only useful for absolute
                z values <= 6.  For z values > to 6.0, poz() returns 0.0.
    */

   function poz(z) {
     var y, x, w;

     if (z == 0.0) {
       x = 0.0;
     } else {
       y = 0.5 * Math.abs(z);
       if (y > Z_MAX * 0.5) {
         x = 1.0;
       } else if (y < 1.0) {
         w = y * y;
         x =
           ((((((((0.000124818987 * w - 0.001075204047) * w + 0.005198775019) *
             w -
             0.019198292004) *
             w +
             0.059054035642) *
             w -
             0.151968751364) *
             w +
             0.319152932694) *
             w -
             0.5319230073) *
             w +
             0.797884560593) *
           y *
           2.0;
       } else {
         y -= 2.0;
         x =
           (((((((((((((-0.000045255659 * y + 0.00015252929) * y -
             0.000019538132) *
             y -
             0.000676904986) *
             y +
             0.001390604284) *
             y -
             0.00079462082) *
             y -
             0.002034254874) *
             y +
             0.006549791214) *
             y -
             0.010557625006) *
             y +
             0.011630447319) *
             y -
             0.009279453341) *
             y +
             0.005353579108) *
             y -
             0.002141268741) *
             y +
             0.000535310849) *
             y +
           0.999936657524;
       }
     }
     return z > 0.0 ? (x + 1.0) * 0.5 : (1.0 - x) * 0.5;
   }

   /*  CRITZ  --  Compute critical normal z value to
                   produce given p.  We just do a bisection
                   search for a value within CHI_EPSILON,
                   relying on the monotonicity of pochisq().  */

   function critz(p) {
     var Z_EPSILON = 0.000001; /* Accuracy of z approximation */
     var minz = -Z_MAX;
     var maxz = Z_MAX;
     var zval = 0.0;
     var pval;

     if (p < 0.0 || p > 1.0) {
       return -1;
     }

     while (maxz - minz > Z_EPSILON) {
       pval = poz(zval);
       if (pval > p) {
         maxz = zval;
       } else {
         minz = zval;
       }
       zval = (maxz + minz) * 0.5;
     }
     return zval;
   }

   /*  TRIMFLOAT  --  Trim floating point number to a given
                       number of digits after the decimal point.  */

   function trimfloat(n, digits) {
     var dp, nn, i;

     n += "";
     dp = n.indexOf(".");
     if (dp != -1) {
       nn = n.substring(0, dp + 1);
       dp++;
       for (i = 0; i < digits; i++) {
         if (dp < n.length) {
           nn += n.charAt(dp);
           dp++;
         } else {
           break;
         }
       }

       /* Now we want to round the number.  If we're not at
               the end of number and the next character is a digit
               >= 5 add 10^-digits to the value so far. */

       if (dp < n.length && n.charAt(dp) >= "5" && n.charAt(dp) <= "9") {
         var rd = 0.1,
           rdi;

         for (rdi = 1; rdi < digits; rdi++) {
           rd *= 0.1;
         }
         rd += parseFloat(nn);
         rd += "";
         nn = rd.substring(0, nn.length);
         nn += "";
       }

       //  Ditch trailing zeroes in decimal part

       while (nn.length > 0 && nn.charAt(nn.length - 1) == "0") {
         nn = nn.substring(0, nn.length - 1);
       }

       //  Skip excess decimal places before exponent

       while (dp < n.length && n.charAt(dp) >= "0" && n.charAt(dp) <= "9") {
         dp++;
       }

       //  Append exponent, if any

       if (dp < n.length) {
         nn += n.substring(dp, n.length);
       }
       n = nn;
     }
     return n;
   }

   //  CALC_Z  --  Button action to calculate Q from Z

   function calc_z(p) {
     if (Math.abs(p) > Z_MAX) {
       alert(
         "Error: z value must be between -6 and 6.\nValues outside this range have probabilities\nwhich exceed the precision of calculation used in this page."
       );
     return  "*Error*";
     } else {
       var qz = 1 - poz(Math.abs(p));

       return  trimfloat(qz, ROUND_FLOAT);
       
     }
   }

   //  CALC_Q  --  Button action to calculate Z from Q

   function calc_q(p) {
     if (p< 0 || p > 1) {
       alert("Probability (Q) must be between 0 and 1.");
       return "*Error*";
     } else {
       return trimfloat(Math.abs(critz(p)), ROUND_FLOAT);
     }
   }