import React from "react";
import EditSpecificationButton from "./EditSpecificationButton";
import User from "../../types/models/User";
import BvSpecification from "../../types/models/BvSpecification";
import PasteSpecificationButton from "./PasteSpecificationButton";

type BIVACProps = {
  user: User;
  specification: BvSpecification;
};

function BIVACTools({ user, specification }: BIVACProps) {
  if (user) {
    return (
      <>
        <EditSpecificationButton specification={specification} />
        <PasteSpecificationButton specification={specification} />
      </>
    );

  } else {
    return <div></div>;
  }
}

export default BIVACTools;
