import React, { useState, useEffect, RefObject } from "react";
import { Alert, Badge, Table } from "react-bootstrap";
import BvSpecification from "../../types/models/BvSpecification";
import Reference from "./Reference"
import extractArraysFromMetaAnalysis from "../Services/Calculators/extractArraysFromMetaAnalysis";
import StateOfWellBeing from "../StateOfWellBeing/StateOfWellBeing"
import SamplingIntervals from "../ui/SamplingIntervals";
import User from "../../types/models/User";
import Matrix from "../Matrix/Matrix";

type ReferencesForMetaAnalysisTypedProps = {
  analyte_id: number,
  references_used_in_metanalysis?: number[][],
  user: User
};

function ReferencesForMetaAnalysisTyped({
  analyte_id,
  references_used_in_metanalysis,
  user
}: ReferencesForMetaAnalysisTypedProps) : JSX.Element{
  
  const [uniqueItems, setUniqueItems] = useState(Array<number>);
  const [dataset, setDataset] = useState([]);
  
  useEffect(() => {

    setUniqueItems(extractArraysFromMetaAnalysis (references_used_in_metanalysis!));
    
    const fetchData = async () => {
      // get the data from the api
      const api_data = await fetch(
        "/api/analyte_specifications/" + analyte_id,
        {
          method: "get",
        }
      );
      // convert the data to json
      const json = await api_data.json();
      setDataset(json)
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  
  const renderAllReferenceForAnalyte = (item: BvSpecification) => {
    let backgroundColor = "table-warning";

    if (uniqueItems.includes(item.id, 0)) {
      backgroundColor = "table-success";
    }

    return (
      <tr key={item.id} className={backgroundColor}>
        <td>
          <Reference reference={item.reference} user={user} />
        </td>
        <td className="align-middle">
          <a className="btn btn-primary btn-sm" href={"/bv_specifications/" + item.id}>View</a>
        </td>
        <td className="fs-5 align-middle font-monospace">{item.estimates_of_cvi}</td>
        <td className="fs-5 align-middle font-monospace">{item.estimates_of_cvg}</td>
        <td className="fs-6 align-middle text-nowrap col-sm-auto">{item.sex_for_meta_analysis}</td>
        <td className="fs-6 align-middle">{item.age_mean}</td>
        <td className="fs-6 align-middle white-space ">
          <StateOfWellBeing
            simple={true}
            state_of_well_being={item.state_of_well_being}
          />
        </td>
        <td className="fs-6 align-middle">
          <b>
            <Matrix id={item.matrix.id} />
          </b>
        </td>
        <td className="fs-6 align-middle">
          <SamplingIntervals
            samples_per={item.samples_per}
            interval_units={item.interval_units}
          />
        </td>
      </tr>
    );
  };
      return (
      <>
        <h3>Colour Key for References</h3>
        <Badge key={"include"} bg={"success"}>
          Included In Meta Analysis
        </Badge>
        <Badge key={"not_include"} bg={"warning"}>
          Not Included In Meta Analysis
        </Badge>
        <hr></hr>
        <Table>
          <thead>
            <tr>
              <th>
                <h3>Reference</h3>
              </th>
              <th>
                Dataset
              </th>
              <th>Estimate of CVI</th>
              <th>Estimate of CVG</th>
              <th className="col-sm-auto">Gender</th>
              <th>Age</th>
              <th>State of Well Being</th>
              <th>Matrix</th>
              <th>Sampling Interval</th>
            </tr>
          </thead>
          <tbody>
            {dataset.map(renderAllReferenceForAnalyte)}
          </tbody>
        </Table>
      </>
    );



}

export default ReferencesForMetaAnalysisTyped