import React from "react";
import { Row } from "react-bootstrap";
import DataTables from "../../types/DataTables";

const SpecsDataTableInputForMetacalculation = ({
  between_data, within_data,
}: DataTables) => {

  let within_table = create_html_table(within_data);
  let between_table = create_html_table(between_data);

  function create_html_table(table_string: string) {
    let table_array = table_string.split(",");
    let table_html = "<table><tr>";
    var ele = document.createElement("div");
    table_array.slice().forEach(function (v) {
      let x = v.split(/\r?\n/);

      if (x[1] == null) {
        table_html += "<td>" + v + "</td>";
      } else {
        table_html +=
          "<td>" + x[0] + "</td>" + "</tr>" + "<tr><td>" + x[1] + "</td>";
      }
    });
    return table_html + "</tr></table>";
  }

  return (
    <>
      <h3>Input Tables</h3>
      <Row>
        <h4>Within Calculation</h4>
        <div dangerouslySetInnerHTML={{ __html: within_table }} />
      </Row>
      <Row>
        <h4>Between Calculation</h4>
        <div dangerouslySetInnerHTML={{ __html: between_table }} />
      </Row>
    </>
  );
};

export default SpecsDataTableInputForMetacalculation;
