import React from "react";
import { Popover } from "react-bootstrap";

function BIVACPopOver(props) {

  return (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        Biological Variation Data Critical Appraisal Checklist (BIVAC)
      </Popover.Header>
      <Popover.Body>
        <div>
          <small>
            The BIVAC is designed to assess the quality of BV publications by
            verifying whether all essential elements that may impact upon
            veracity and utility of the associated BV estimates are present. The
            main focus of the BIVAC is the effect of study design, the
            measurement procedure and statistical handling of data on
            within-subject BV estimates (CVI). The BIVAC consists of 14 quality
            items (QI), which can be awarded scores A, B, C or D, indicating
            decreasing compliance. Based on the individual scores for each of
            the QI, an overall grade is set for the publication. The grade A is
            achieved if the study shows full compliance with all BIVAC QI. If
            the lowest score for any QI is a B, then the overall grade is a B
            and similarly C or D if the lowest QI score is a C or D,
            respectively. In the BIVAC scoring system, the QIs related to the
            overall grade are shown as a subscript. BV estimates derived from
            studies that receive one or more D scores are considered unsuitable
            for use in clinical practice and are not included in the EFLM
            Biological Variation Database. Two different assessors independently
            score each publication, performing data review and data extraction
            for all measurands and subgroups (study populations, sampling
            intervals) independently. If assessors disagree, a third assessor
            reviews the publication, followed by discussion with the initial
            assessors or a larger panel to deliver a consensus grade.
          </small>
        </div>

        <div>
          <hr></hr>
          <small>
            <a href="https://doi.org/10.1373/clinchem.2017.281808">
              {" "}
              Aasne K Aarsand, Thomas Røraas, Pilar Fernandez-Calle, Carmen
              Ricos, Jorge Díaz-Garzón, Niels Jonker, Carmen Perich, Elisabet
              González-Lao, Anna Carobene, Joana Minchinela, Abdurrahman Coşkun,
              Margarita Simón, Virtudes Álvarez, William A Bartlett, Pilar
              Fernández-Fernández, Beatriz Boned, Federica Braga, Zoraida Corte,
              Berna Aslan, Sverre Sandberg, The Biological Variation Data
              Critical Appraisal Checklist: A Standard for Evaluating Studies on
              Biological Variation, Clinical Chemistry, Volume 64, Issue 3, 1
              March 2018, Pages 501–514
            </a>
          </small>
        </div>
      </Popover.Body>
    </Popover>
  );

}

export default BIVACPopOver;
