import React from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EditReferenceButton from "../ui/EditReferenceButton";
import ReferenceUsedIn from "../Reference/ReferenceUsedIn";
import Reference from "../Reference/Reference";

// ...

const NoDataIndication = () => (
    <div className="spinner">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
    </div>
);


class MetacalculationsTable extends React.Component {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
        // Set the state directly. Use props if necessary.
        this.state = {
            references: [],
            page: 1,
            sizePerPage: 10,
            data: [],
            totalSize: 0
        };
    }
    
    columns = [   
        {
        dataField: 'df3',
        isDummyField: true,
        text: 'Reference',
        headerStyle: { width: '30%' },
        formatter: (cellContent, row) => {
           return (<Reference reference={row}/>) 
        },
      },
    {
        dataField: 'df1',
        isDummyField: true,
        text: 'Used In',
        headerStyle: { width: '50%' },
        formatter: (cellContent, row) => {
          return (<ReferenceUsedIn reference_id={row.id}/>) 
       },
      },
    {
        dataField: 'df2',
        isDummyField: true,
        text: 'Tools',
        headerStyle: { width: '100px' },
        formatter: (cellContent, row) => {
          if (this.props.user_id) {
            return (
              <EditReferenceButton reference_id={row.id}/>
            );
          }
          return (
            <h5>
              <span className="label label-danger"></span>
            </h5>
          );
        }
      },
    ];


    async componentDidMount() {
        if (!this.props.reference) {
            await axios.get("/api/references/").then((response) => {
                this.setState(
                    {
                        references: response.data.references,
                        data: response.data.references.slice(0, 0 + 10),
                        totalSize: response.data.references.length
                    },
                );
            });
        } else {
            this.setState({ references: this.props.references });
        }
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.setState(() => ({
            page,
            sizePerPage,
            data: this.state.references.slice(currentIndex, currentIndex + this.state.sizePerPage),

        }));
    }


    render() {
       const { data, sizePerPage, page, totalSize } = this.state;

        return (

            <div>
              <BootstrapTable
                remote
                keyField="id"
                data={data}
                columns={this.columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={this.handleTableChange}
                noDataIndication={() => <NoDataIndication />}
            />
            </div>
        );
    }
}

export default MetacalculationsTable;