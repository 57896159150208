import React from "react";
import { Modal, Button } from "react-bootstrap";
import BIVACDatasetScoring from "../BIVAC/BIVACDatasetScoring";
import BIVACDatasetItems from "../BIVAC/BIVACDatasetItems";
import BIVACDatasetCoding from "../BIVAC/BIVACDatasetCoding";
import BIVACDatasetSummary from "../BIVAC/BIVACDatasetSummary";
import BvSpecification from "../../types/models/BvSpecification";

interface ViewSpecificationButtonProps {
  specification: BvSpecification;
}

type MyState = {
  error?: string;
  modal?: boolean;
  fullscreen?: boolean;
};

class ViewSpecificationButton extends React.Component<
ViewSpecificationButtonProps, MyState> {
  
  state = {
    modal: false,
    fullscreen: false
  };
  
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      fullscreen: true
    });
  };
  
  handleClose = () => {
    this.setState({
      modal: !this.state.modal,
      fullscreen: false
    });
  };
  
  render() {
    return (
      <React.Fragment>
        <div className="d-grid gap-2">
          <Button  variant="secondary" onClick={this.toggle}>
            View Details
          </Button>
        </div>
        <Modal
          show={this.state.modal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          fullscreen={this.state.fullscreen}
        >
          <Modal.Header closeButton onHide={this.handleClose}></Modal.Header>
          <Modal.Body>
            <BIVACDatasetSummary specification={this.props.specification} />
            <BIVACDatasetScoring specification={this.props.specification} />
            <BIVACDatasetItems specification={this.props.specification} />
            <BIVACDatasetCoding specification={this.props.specification} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
    }
  }
  
  export default ViewSpecificationButton;
  