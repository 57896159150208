import React from "react";
import BIVACDatasetCompactSummary from "../BIVAC/BIVACDatasetCompactSummary";
import BIVACTools from "../ui/BIVACTools";
import ViewSpecificationButton from "../ui/ViewSpecificationButton";
import IncludeInMeta from "../ui/IncludeInMetaAnalysis";
import { Accordion } from "react-bootstrap";
import BvSpecification from "../../types/models/BvSpecification";
import User from "../../types/models/User";
import Analyte from "../../types/models/Analyte";

type ReferenceUsedInProps = {
  bv_specifications: Array<ReferenceResponses>;
  user: User;
};

type ReferenceResponses = {
  specification: ReferenceResponse;
};

type ReferenceResponse = {
  bv_specification: BvSpecification;
  analyte: Analyte;
};


function ReferenceUsedIn({ bv_specifications, user }: ReferenceUsedInProps) {
  var rows = [];

  for (const [idx, [key, bv_dataset]] of Object.entries(
    Object.entries(bv_specifications)
  )) {
    rows.push(
      <div id={key} key={idx}>
        <Accordion.Item eventKey={key}>
          <Accordion.Header className="panel primary">
            {bv_dataset.specification.analyte.display_name} &nbsp;
            <IncludeInMeta
              specification={bv_dataset.specification.bv_specification}
            />
          </Accordion.Header>
          <Accordion.Body>
            <BIVACDatasetCompactSummary
              specification={bv_dataset.specification.bv_specification}
            />
            <ViewSpecificationButton
              specification={bv_dataset.specification.bv_specification}
            />
            <hr></hr>
            <BIVACTools
              specification={bv_dataset.specification.bv_specification}
              user={user}
            />
          </Accordion.Body>
        </Accordion.Item>
      </div>
    );
  }
  return <Accordion>{rows}</Accordion>;
}

export default ReferenceUsedIn;
