import React, { useState} from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Select from "react-select";
import { SingleValue } from "react-select/dist/declarations/src/types";
import { Reference } from "../../types/models/Reference";
import User from "../../types/models/User";
import TableOfReferences from "../Reference/TableOfReferences";
import BvSpecification from "../../types/models/BvSpecification";
import IsLoading from "../ui/IsLoading";

type FilterandManageByReferenceProps = {
  user: User;
  references: [Reference];
};

type Dataset = {
  dataset: Array<BvSpecification>
}

function FilterandManageByReference({
  user,
  references,
}: FilterandManageByReferenceProps) {

  const [selectedOption, setSelectedOption] =
    useState<SingleValue<{ value: Reference; label: string }>>();

  const [specifications, setSpecifications] = useState<BvSpecification[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const submitSelection = (event: React.MouseEvent<HTMLButtonElement>) => {

    if (selectedOption) {
      setLoading(true);

      axios
        .get<Dataset>(
          "/api/bv_specifications/manage_by_reference/" +
            selectedOption?.value.id,
          {}
        )
        .then(function (response) {
          setSpecifications(response.data.dataset);
          setLoading(false)
        })
        .catch(function (error) {
        });
    }
  };

  const onChange = (
    option: SingleValue<{ value: Reference; label: string }>
  ) => {
    setSelectedOption(option);
  };

  if (user !== null) {
    var options = references.map(function (reference) {
      var option = {
        value: reference,
        label:
          reference.title +
          ", " +
          reference.author +
          ", " +
          reference.journal +
          ", " +
          reference.year_of_publication +
          ", database id: " +
          reference.id  +
          ", Spanish database id: " +
          reference.link_to_spanish_database,
      };
      return option;
    });

    return (
      <>
        <div className="mb-2">
          <h3>Filter and Manage By Reference</h3>
          <IsLoading IsLoading={isLoading} />
          <Select
            options={options}
            defaultValue={selectedOption}
            onChange={onChange}
          />
        </div>
        <div className="mb-2">
          <Button variant="primary" onClick={submitSelection}>
            Manage Reference Dataset
          </Button>
        </div>
        {specifications ? (
          <div className="mb-2">
            <TableOfReferences
              user={user}
              specifications={specifications}
              reference={selectedOption?.value} 
              export_url={"/export_by_reference/" + selectedOption?.value.id + ".xlsx"} />
          </div>
        ) : (
          "No References"
        )}
      </>
    );
  } else {
    return <h3>Not Authorised</h3>;
  }
}

export default FilterandManageByReference;
