import React from "react";

function CiteReference(props) {
  const href_link = "/references/" + props.reference.id;
  return (
    <a href={href_link}>
      {props.reference.title},
      <cite title="Source Title">
        {" "}
        {props.reference.author}, {props.reference.journal},{" "}
        {props.reference.volume}, {props.reference.pages},{props.reference.year}
      </cite>
    </a>
  );
}

export default CiteReference;
