import React from "react";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import * as general from "packs/general_functions";

const BIVACDatasetCompactSummary = ({ specification }) => {
    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Score</th>
                    <th>Estimates of CVG</th>
                    <th>Estimates of CVI</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><div
                        dangerouslySetInnerHTML={{
                            __html: general.displayTotalScore(
                                specification.total_score
                            ),
                        }}
                    >
                    </div></td>
                    <td><NumberFormat
                        value={specification.estimates_of_cvg}
                        displayType={"text"}
                        decimalScale="2"
                    /></td>
                    <td><NumberFormat
                        value={specification.estimates_of_cvi}
                        displayType={"text"}
                        decimalScale="2"
                    /></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default BIVACDatasetCompactSummary;