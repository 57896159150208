import React, { useEffect, useState } from "react";
import axios from "axios";
import Analyte from "../../types/models/Analyte";
import User from "../../types/models/User";
import MetaAnalysis from "../../types/models/MetaAnalysis";
import MetaByAnalyteApiResponse from "../../types/api_responses/MetaByAnalyte";
import MetaCalculationsSummaryTableTyped from "./MetaCalculationsSummaryTableTyped";
import extractVariation from "../Services/extractVaration";
import ReferencesForMetaAnalysisTyped from "../Reference/ReferencesForMetaAnalysisTyped";
import ReferencesForMeasurand from "../Reference/ReferencesForMeasurand";

type MetaCalculationForMeasurandType = {
  user: User;
  measurand: Analyte;
};

function renderMeta(recs: Array<MetaAnalysis>, analyte: Analyte): JSX.Element {
  const data = extractVariation(recs)

  return(
    <MetaCalculationsSummaryTableTyped analyte={analyte} matrix={data.matrix} within_data={data.within_data} between_data={data.between_data} />
  )
}

function MetaCalculationForMeasurand({
  user,
  measurand,
}: MetaCalculationForMeasurandType) {
  const [metacalculations, setMetacalculations] =
    useState<MetaByAnalyteApiResponse>({} as MetaByAnalyteApiResponse);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState(null);
  var references: number[][] | undefined = [];

  useEffect(() => {
    const query = "/api/meta_calculations/meta_by_analyte/" + measurand.id;
    axios
      .get(query, {})
      .then(function (response) {
        setIsLoaded(true);
        setMetacalculations(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoaded(true);
        setError(error);
      })
  }, []);

  if (isLoaded && metacalculations.data && metacalculations.data.metas) {
      references = metacalculations.data.metas.map((x : MetaAnalysis) =>
      x.references_array)
  }

  if (isLoaded && metacalculations.data && metacalculations.data.metas.length > 0)
    return (
      <React.Fragment>
        <div>{renderMeta(metacalculations.data.metas, measurand)}</div>
        <div>
          <ReferencesForMetaAnalysisTyped
            analyte_id={measurand.id}
            references_used_in_metanalysis={references}
            user={user}
          />
        </div>
      </React.Fragment>
    );
  else
    return measurand && isLoaded ? (
      <>
        <>
          <span>{measurand.display_name}: </span>
          <span className="text-warning bg-dark">No Meta-Analysis</span>
        </>
        <div>
          <ReferencesForMeasurand analyte_id={measurand.id} user={user} />
        </div>
      </>
    ) : (
      <span>Loading.....</span>
    );
}

export default MetaCalculationForMeasurand;


