import React, { useState, useEffect } from "react";
import axios from "axios";
import ApiMatrix from "../../types/api_responses/ApiMatrix";

type MatrixProps = {
  id: Number
};

function Matrix({id}: MatrixProps) : JSX.Element {
  const [matrix, setMatrix] = useState({} as ApiMatrix);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/api/matrices/" + id);
      setMatrix(result.data);
    };

    fetchData();
  }, []);
  if (matrix.matrix === undefined) return <b><p className="text-warning">No Matrix</p></b>
  else {return (
    <b>
      <p className="text-success">
        {matrix.matrix.matrix_expansion} {" "} {matrix.matrix.matrix_explanation}
      </p>
    </b>
  );}

}

export default Matrix;
