import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import NavMenu from "./NavMenu";

class NavBar extends React.Component {
    componentDidMount() { }

    render() {
        return (
            <Navbar bg="light" expand="lg" >
                <Container className="p-2">
                    <Navbar.Toggle aria-controls="bottom-nav"></Navbar.Toggle>
                    <Navbar.Collapse id="bottom-nav">
                        <Nav className="me-auto">
                            <NavMenu />
                        </Nav>
                        <Nav className="justify-content-end" activeKey="/home">
                            <Nav.Link href="/about">About EFLM BVD</Nav.Link>
                            <Nav.Link href="/eflm-wg">Publications</Nav.Link>
                            <Nav.Link href="/publication_structure">How to Write a Paper on BV</Nav.Link>
                            <Nav.Link href="/background">Background</Nav.Link>
                            <Nav.Link href="/disclaimer">Disclaimer</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            
        );
    }
}

export default NavBar;