import React from "react";

type SamplingIntervalsProps = {
  samples_per?: number;
  interval_units?:string;
};

function SamplingIntervals({ interval_units, samples_per}: SamplingIntervalsProps) : JSX.Element{
  if (samples_per && interval_units) {
    return (
    <span>{samples_per}   per  {interval_units}</span>
    )
  } else {
    return <span>"No Sampling Intervals"</span>;
  }
}

export default SamplingIntervals;
