import React from 'react'
import {  Row, Container } from 'react-bootstrap';

import Search from '../Search/Search';
import ReferenceJumbotron from '../ui/ReferenceJumbotron';
import OptionCards from '../ui/OptionCards';
import HomePageHeader from '../ui/HomePageHeader';

const HomePage = () => {
  return (
    <React.Fragment>
      <Container>
        <HomePageHeader />

        <Row className='p-2'>
          <Container className='w-50'>
            <Search buttonColor='primary'/>
          </Container>
        </Row>

        <Row>
          <OptionCards />
        </Row>
        <Row>
          <Container className='w-95'>
            <div className="d-flex justify-content-center">
              <ReferenceJumbotron />
            </div>
          </Container>
        </Row>

      </Container>
    </React.Fragment >
  );
}

export default HomePage;