import ExtractVariation from "../../types/ExtractVariation";
import { Matrix } from "../../types/models/Matrix";
import MetaAnalysis from "../../types/models/MetaAnalysis";

function extractVariation(metas: Array<MetaAnalysis>) : ExtractVariation{
  
  var within_data : MetaAnalysis ={
    id: 0,
    analyte_id: 0,
    comments: "",
    display: false,
    matrix: {id:0, matrix_expansion: "loading", matrix_explanation: "no data"},
    matrix_id: 0,
    median: 0,
    number_used: 0,
    references_array: [],
    var_type: ""
  };
  
  var between_data : MetaAnalysis ={
    id: 0,
    analyte_id: 0,
    comments: "",
    display: false,
    matrix: {id:0, matrix_expansion: "loading", matrix_explanation: "no data"},
    matrix_id: 0,
    median: 0,
    number_used: 0,
    references_array: [],
    var_type: ""
  };
  
  var meta_id : number = 0;
  
  var matrix : Matrix = {
    id: 0,
    matrix_expansion: "",
    matrix_explanation: ""
  }

  //extract the separate types into individual data elements
  metas.forEach(extract);

  function extract(item: MetaAnalysis) {
    if (item.var_type == ":cvg") {
      between_data = item;
      meta_id = item.id;
    }

    if (item.var_type == ":cvi") {
      within_data = item;
      meta_id = item.id;
    }
    matrix = item.matrix
  }

  const data = {within_data: within_data,
          between_data: between_data,
          meta_id: meta_id,
          matrix: matrix}
  
  return data
}

export default extractVariation;