import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

function LogoutButton(props) {
    return(
    <a href="/users/sign_out" data-method="delete">Delete</a>
    )
}


export default LogoutButton;

