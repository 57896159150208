import React from "react";

type IsValidatedProps = {
  result: boolean;
  reason: string;
};

const IsValidated = ({ result, reason }: IsValidatedProps) => {
  if (result == true) {
    return (
      <div>
        <span className="badge bg-success">
          <b>Included in Meta-Analysis</b>
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="badge bg-danger">
          <b>Not Validated</b>
        </span><br />{reason}
      </div>
    );
  }
};

export default IsValidated;
