import React from "react";
import {Badge} from 'react-bootstrap';

function IsHealthy(props) {
  if (props.healthy == true) {
    return <div><Badge bg="success">Healthy Population</Badge></div>;
  } else {
    return <div><Badge bg="warning">Non-Healthy Population</Badge></div>;
  }
}

export default IsHealthy;
