import React from "react";

function ConfidenceIntervals(props) {
    return (
    <span>
        {" ("}
        <LowerLimit  ciLower = {props.ciLower} /> - <UpperLimit  ciUpper = {props.ciUpper} />
        {" )"}
    </span>
    )
}

function LowerLimit(value){
    if (value.ciLower == "NaN") {
        return "No Lower Limit Defined";
      } else {
        return value.ciLower;
      }
}

function UpperLimit (value){
    if (value.ciUpper == "NaN") {
        return "No Upper Limit Defined";
      } else {
        return value.ciUpper;
      }
}

export default ConfidenceIntervals;
