import React, { useState, useEffect } from "react";
import axios from "axios";

function NumberList(data) {
  var arr = [];
  Object.keys(data).forEach(function (key) {
    arr.push(data[key]);
  });
  return <ul>{arr.map(item => <div key={item.label} label={item.label} value={item.value} />)}</ul>;
}

function MetaCalculator(props) {

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("/api/bv_specifications/specs_for_analytes/").then((response) => {
      setData(response.data.datasets)
    });
  }, []);

  function displayRecord(key) {
    return <li >{data[key]}xxxx</li>;
  };

  return (
    <p>
      {Object.keys(data).map((innerAttr, index) => {
        return (
          <span key={index}> {innerAttr}:  <br /></span>
        )
      })
      }
    </p>
  );

};



export default MetaCalculator;
