import React from "react";
import { Table, Card } from "react-bootstrap";
import IncludeInMeta from "../ui/IncludeInMetaAnalysis";
import IsPublished from "../ui/IsPublished";
import IsHealthy from "../ui/IsHealthy";
import IsReviewed from "../ui/IsReviewed";

class BIVACDatasetCoding extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Card >
        <Card.Title className="bg-primary text-light">Dataset Coding</Card.Title>
        <Table striped >
          <thead>
            <tr>
              <th width="50%">Parameter</th>
              <th width="50%">Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Healthy Population</td>
              <td>
                <IsHealthy healthy={this.props.specification.healthy} />
              </td>
            </tr>
            <tr>
              <td>Adult Population</td>
              <td>{this.props.specification.adult}</td>
            </tr>
            <tr>
              <td>Weekly Samplings</td>
              <td>{this.props.specification.weekly_samplings}</td>
            </tr>
            <tr>
              <td>Reviewed</td>
              <td>{this.props.specification.review}
              <IsReviewed reviewed={this.props.specification.review} /></td>
            </tr>
            <tr>
              <td>Published</td>
              <td>
                <IsPublished published={this.props.specification.published} />
              </td>
            </tr>

            <tr>
              <td>Include in Meta-Analysis</td>
              <td>
                <IncludeInMeta
                  specification={
                    this.props.specification
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Gender for Meta-Analysis</td>
              <td>{this.props.specification.sex_for_meta_analysis}</td>
            </tr>
            <tr>
              <td>Snomed CT</td>
              <td>{this.props.specification.snomed_ct}</td>
            </tr>
            <tr>
              <td>NPU</td>
              <td>{this.props.specification.npu}</td>
            </tr>
            <tr>
              <td>LOINC</td>
              <td>{this.props.specification.loinc}</td>
            </tr>
            <tr>
              <td>Comment</td>
              <td>{this.props.specification.comment}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    );
  }
}

export default BIVACDatasetCoding;
