import React from "react";
import Analyte from "../Analyte/Analyte";

function AnyAnalyte(props) {
    if (props.specification.analyte) {
        return (
            <div>No Analyte</div>
        )
    } else {
        return (
            <Analyte id={props.specification.analyte_id}/>
        )
    }
}

export default AnyAnalyte;