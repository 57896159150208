import React from "react";
import { Matrix } from "../../types/models/Matrix";

type IsMatrixProps = {
    id?: Number
    matrix?: Matrix
};

function IsMatrix({id, matrix}: IsMatrixProps) : JSX.Element{

    if (matrix || id) {
        return <span>{matrix!.matrix_expansion}</span>;
    } else {
        return <span>"No Matrix Set"</span>;
    }
}

export default IsMatrix;