import axios from "axios";

async function GetJob(id) {
  try {
    let res = await axios({
      url: "/api/queue_jobs/" + id,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
      }
    })
    if (res.status == 200) {
      // test for status you want, etc
      console.log(res.status)
    }
    // Don't forget to return something   
    return res
  }
  catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config)
  }
}

export default GetJob;