import React from "react";
import { NavDropdown } from "react-bootstrap";


class NavMenu extends React.Component {
    componentDidMount() { }

    render() {
        return (
                <NavDropdown title="BV Data" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/references">By Reference</NavDropdown.Item>
                    <NavDropdown.Item href="/bv_specifications/measurand">By Measurands</NavDropdown.Item>
                    <NavDropdown.Item href="/matrices">List of Matricies</NavDropdown.Item>
                <NavDropdown.Item href="/meta_calculations">List of BV estimates</NavDropdown.Item>
                    <NavDropdown.Item href="/bv_specifications">All BV Data</NavDropdown.Item>
                </NavDropdown>
        );
    }
}

export default NavMenu;