import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

function UserMenu(props) {
    if (props.user_id !== null) {
        return (
            <NavDropdown title="Account" id="basic-nav-dropdown">
                <NavDropdown.Item href="/users/edit">Change Password</NavDropdown.Item>
                <NavDropdown.Item  href="/users/sign_out" data-method='delete'>Logout</NavDropdown.Item >
            </NavDropdown>
        )
    } else {
        return (
            <Nav.Item>
                <Nav.Link href="/users/sign_in">Login</Nav.Link>

            </Nav.Item>

        );
    }
}


export default UserMenu;

