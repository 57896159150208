import $ from 'jquery';
export function calculateRCV(data) {

    $("#main_body").on('click', '#calculate_rcv', function(e) {

        document.getElementById("answer").style.display = "block";

        var theForm = document.forms["rcv_form"];
        const cvi = theForm.elements["cvi_form"].value;
        const analytical_CV = theForm.elements["analyticalCV_form"].value;
        const zscore = getZScore();

        const intermediate = Math.sqrt(Math.pow(cvi, 2) + Math.pow(analytical_CV, 2));
        const rcv = intermediate * Math.SQRT2 * zscore;

        $("#rcv_answer").html(rcv.toFixed(1));

    });

    $("#main_body").on('click', '#close', function(e) {
        off()
    });


    function getZScore() {
        // var zscores= new Array();
        // zscores["90"]=1.65;
        // zscores["95"]=1.96;
        // zscores["96"]=2.05;
        // zscores["97"]=2.17;
        // zscores["98"]=2.33;
        // zscores["99"]=2.58;

        var zscore = 0;
        var theForm = document.forms["rcv_form"];
        var selectedZScore = theForm.elements["zvalue"];
        zscore = selectedZScore.value;
        return zscore;
    }

}