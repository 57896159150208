import React, { useState, useEffect, useRef, JSXElementConstructor } from "react";
import axios from "axios";
import EditReferenceButton from "../ui/EditReferenceButton";
import {
  Modal,
  Button,
  ButtonGroup, Table,
  Container,
  NavLink,
} from "react-bootstrap";
import User from "../../types/models/User";
import { Reference as ReferenceType} from "../../types/models/Reference";

type ReferencePropsType = {
  reference_id?: number;
  user?: User;
  reference?: ReferenceType;
};

function Reference({reference_id, reference, user} : ReferencePropsType) {

  const [show, setShow] = useState(false);
  const [ref, setReference] = useState<ReferenceType>();

  useEffect(() => {
    if (!reference!) {
      axios.get("/api/references/" + reference_id).then((response) => {
        setReference(response.data.reference)
      });
    } else {
      setReference(reference)
    }
  }, []);

  const ToolBar = () => {
    if (user) {
      return (
        <>
          <ButtonGroup>
            <Button onClick={() => setShow(!show)} size="sm" variant="success">
              View
            </Button>
            <EditReferenceButton reference={reference!} user={user} />
          </ButtonGroup>
        </>
      );
    }
    return <div></div>
  }

  const popover = (): JSX.Element => {
    return (
      <div>
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Title</td>
                <td>{ref!.title}</td>
              </tr>
              <tr>
                <td>Authors</td>
                <td>{ref!.author}</td>
              </tr>

              <tr>
                <td>Year of Publication</td>
                <td>{ref!.year_of_publication}</td>
              </tr>
              <tr>
                <td>Pubmed Link</td>
                <td>
                  <a href={ref!.pubmed_link}>{ref!.pubmed_link}</a>
                </td>
              </tr>
              <tr>
                <td>Pages</td>
                <td>{ref!.pages}</td>
              </tr>
              <tr>
                <td>Volume</td>
                <td>{ref!.volume}</td>
              </tr>

              <tr>
                <td>doi</td>
                <td>{ref!.doi}</td>
              </tr>
              <tr>
                <td>Links</td>
                <td>{ref!.link}</td>
              </tr>
              <tr>
                <td>Article ID</td>
                <td>{ref!.link_to_spanish_database}</td>
              </tr>
              <tr>
                <td>Files</td>
                <td>
                  <a href={ref!.article_url}>{ref!.article_file_name}</a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }

  const render = (): JSX.Element => {
    return (
      <>
        <NavLink className="fs-5" onClick={() => setShow(!show)}>
          {ref!.title}
          <br />
          <div>
            <p className="fs-6 mt-2 text-wrap">
              {ref!.author}, {ref!.journal}, {ref!.pages}, {ref!.volume},{" "}
              {ref!.year_of_publication}
            </p>
          </div>
        </NavLink>

        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => setShow(!show)}>
            <Modal.Title>Reference Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>{popover()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(!show)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="text-center">
          <ToolBar />
        </div>
      </>
    );
  };

  return (
    <div>
      {ref ? <div>{render()}</div> : <div>Loading</div>}  
    </div>
  );
}


export default Reference;
