import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import DataTables from "../../types/DataTables";
import Analyte from "../../types/models/Analyte";
import BvSpecification from "../../types/models/BvSpecification";
import MetaAnalysis from "../../types/models/MetaAnalysis";
import User from "../../types/models/User";
import ReferencesForMetaAnalysisTyped from "../Reference/ReferencesForMetaAnalysisTyped";
import createDummyMetaAnalysis from "../Services/createDummyMetaAnalysis";
import GetMatrix from "../Services/GetMatrix";
import MetaCalculationCalculator from "../MetaCalculation/MetaCalculationCalculator";
import MetaCalculationsSummaryTableTyped from "../MetaCalculation/MetaCalculationsSummaryTableTyped";
import SpecificationTable from "../BIVAC/SpecificationsTable";
import SpecsDataTableInputForMetacalculation from "../BIVAC/SpecsDataTableInputForMetacalculation";
import ValidateForMeta from "../BIVAC/ValidateForMeta";
import Matrix from "../Matrix/Matrix"
import ApiMatrix from "../../types/api_responses/ApiMatrix";

type MetaCalculationProps = {
  user: User;
  specs: Array<BvSpecification>;
  data_tables: DataTables;
  rrest: string;
  analyte: Analyte;
};

function MetaCalculation({
  user,
  specs,
  data_tables,
  rrest,
  analyte,
}: MetaCalculationProps) {

  const [withinCalculation, setwithinCalculation] =
    useState<MetaAnalysisResult>();

  const [betweenCalculation, setbetweenCalculation] =
    useState<MetaAnalysisResult>();

  const [matrix_type, setMatrix] = useState({matrix : {id: 1, matrix_expansion: "loading..", matrix_explanation: "loading.."}} as ApiMatrix);

  const [bv_ids, setBvIds] = useState(Array<number>)

  rrest = "https://r.biologicalvariation.eu/metacalculation";


  let within_data: MetaAnalysis | undefined = undefined;
  let between_data: MetaAnalysis | undefined = undefined;

  useEffect(() => {
    let matrix_ids: Array<number> = specs.map((a) => a.matrix_id);

    let uniqueMatrixIds = [...new Set(matrix_ids)];

    if (uniqueMatrixIds.length == 1) {
      // only 1 matrix get matrix
      // call the function
      (async () => {
        const newData = await GetMatrix(uniqueMatrixIds[0]);
        setMatrix(newData);
      })();

    } else if (uniqueMatrixIds.length > 1) {
      // its a consolidated matrix

    }

    setBvIds( specs.map((a) => a.id))

  }, []);

  // Setting BV IDS here, they don't get overwritten and are used in the
  // calculation
  if (withinCalculation) {
    within_data = createDummyMetaAnalysis(
      withinCalculation!,
      bv_ids,
      ":cvi",
      matrix_type,
      analyte
    );
  }

  if (betweenCalculation) {
    between_data = createDummyMetaAnalysis(
      betweenCalculation!,
      bv_ids,
      ":cvg",
      matrix_type,
      analyte
    );
  }

  return (
    <>
      <Row className="mt-3">
        <h2>Meta Calculation Simulator</h2>
        <Col>
          <Row>
            <Matrix id={matrix_type.matrix.id} />
          </Row>
          <Row>
            <h3>BVIACs Selected</h3>
            <SpecificationTable specs={specs} user={user} />
          </Row>
          <Row>
            <ValidateForMeta specs={specs} />
          </Row>
        </Col>

        <Col>
          <Row className="mt-3">
            <h3>Steps in Calculation</h3>
            <SpecsDataTableInputForMetacalculation
              between_data={data_tables.between_data}
              within_data={data_tables.within_data} bv_specs_ids={data_tables.bv_specs_ids}            />
          </Row>
          <Row className="mt-3">
            <h5>CVI</h5>
            <MetaCalculationCalculator
              data={data_tables.within_data}
              rrest={rrest}
              calculationResults={withinCalculation}
              setState={setwithinCalculation}
            />
            <h5>CVG</h5>
            <MetaCalculationCalculator
              data={data_tables.between_data}
              rrest={rrest}
              calculationResults={betweenCalculation}
              setState={setbetweenCalculation}
            />
          </Row>
        </Col>
      </Row>

      <Row className="mt-3">
        {betweenCalculation?.["W.Median"] && withinCalculation?.["W.Median"] ? (
          <>
            <h3>How it Would Look on the Website</h3>
            <MetaCalculationsSummaryTableTyped
              analyte={analyte}
              within_data={within_data}
              between_data={between_data}
              matrix={matrix_type.matrix}
            />

            <ReferencesForMetaAnalysisTyped
              analyte_id={analyte.id}
              references_used_in_metanalysis={[within_data?.references_array!, between_data?.references_array!]} user={user}            />
          </>
        ) : (
          <div></div>
        )}
      </Row>
    </>
  );
}

export default MetaCalculation;
