import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

function AdminMenu(props) {
    if (props.user_id !== null) {
        return (
          <NavDropdown title="Administration" id="basic-nav-dropdown">
            <NavDropdown.Item href="/bv_specifications/new">
              Enter New BIVAC Dataset
            </NavDropdown.Item>
            <NavDropdown.Item href="/references/new">
              Enter New Reference
            </NavDropdown.Item>
            <NavDropdown.Item href="/analyte_filter">
              Filter and Manage Data by Dataset
            </NavDropdown.Item>
            <NavDropdown.Item href="/reference_filter">
              Filter and Manage Data by Reference
            </NavDropdown.Item>
            <NavDropdown.Item href="/units">Units</NavDropdown.Item>
          </NavDropdown>
        );
    } else {
        return (
            <NavDropdown.Divider />
            );
    }
}


export default AdminMenu;
