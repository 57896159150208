import React, { useState, useEffect } from "react";
import axios from "axios";

function MetacalculationChecker(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [metaData, setMetaData] = useState([]);
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    const query = "/api/meta_calculations/by_analyte_matrix_type/" + props.results.analyte + "/" + props.results.matrix + "/:" + props.results.type
    axios.get(query, {
    })
      .then(function (response) {
        setIsLoaded(true);
        setMetaData(response.data.metacalculations);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoaded(true);
        setError(error);
      })
      .then(function () {
        // always executed
      });  
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <p>{metaData.map(d => (<li key={d.id}>{d.name}</li>))} </p>
    );
  }
}

export default MetacalculationChecker;