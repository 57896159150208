import React from "react";
import { Accordion } from "react-bootstrap";
import Analyte from "../../types/models/Analyte";
import User from "../../types/models/User";
import MetaCalculationForMeasurand from "../MetaCalculation/MetaCalculationForMeasurand";

type SearchResultsPropsType = {
  response: Array<any>;
  user: User;
};

function SearchResults({ response, user }: SearchResultsPropsType) {
    
  const renderAllSpecsForAnalyte = (measurand: Analyte) => {
    return (
      <div key={measurand.id}>
        <Accordion.Item eventKey={measurand.id.toString()}>
          <Accordion.Header>{measurand.display_name}</Accordion.Header>
          <Accordion.Body>
            <MetaCalculationForMeasurand measurand={measurand} user={user} />
          </Accordion.Body>
        </Accordion.Item>
      </div>
    );
  };

  // if only one result open the accordian to show it
  const openAccordian = () => {
    if (response.length == 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {openAccordian() ? (
        <Accordion defaultActiveKey={response[0].id.toString()}>
          {response.map(renderAllSpecsForAnalyte)}
        </Accordion>
      ) : (
        <Accordion>
          {response.map(renderAllSpecsForAnalyte)}
        </Accordion>
      )}
    </div>
  );
}

export default SearchResults;
