import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { Table } from "react-bootstrap";
import BvSpecification from "../../types/models/BvSpecification";
import IsValidated from "../ui/IsValidated";

type ValidateForMetaProps = {
  specs: Array<BvSpecification>;
};

type ValidationResult = [boolean, string];

type results = [BvSpecification, ValidationResult, ValidationResult];

function table_rows(api_result: results): any {
  return (
    <tr key={api_result[0].unique_identifier}>
      <td>{api_result[0].reference.id}</td>
      <td>
        <IsValidated result={api_result[1][0]} reason={api_result[1][1]} />
      </td>
      <td>
        <IsValidated result={api_result[2][0]} reason={api_result[2][1]} />
      </td>
    </tr>
  );
}

function ValidateForMeta({ specs }: ValidateForMetaProps) {
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [metaData, setMetaData] = useState<any[]>([]);

  useEffect(() => {
    const ids = specs.map((o) => o["id"]);

    axios
      .post("/api/bv_specifications/validate_for_meta/", {
        ids: ids,
      })
      .then(function (response) {
        // console.log(response);
        setIsLoaded(true);
        setMetaData(response.data.output);
      })
      .catch(function (error) {
        const err = error as AxiosError;
        console.log(err.response?.data);
        setIsLoaded(true);
        setError(true);
      });
  }, []);

  if (error) {
    return <div>Error: contact developer</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <h4>Validation</h4>

        <Table
          striped
          bordered
          hover
          id="validation_table"
          className="dataTable"
        >
          <thead>
            <tr id="validation_table_header">
              <th scope="col">Reference id</th>
              <th scope="col">Validation CVI</th>
              <th scope="col">Validation CVG</th>
            </tr>
          </thead>
          <tbody>{metaData.map((d) => table_rows(d))}</tbody>
        </Table>
      </>
      // </table>
    );
  }
}

export default ValidateForMeta;
