import React from "react";
import BVIACDataset from "./BIVACDataset";
import BvSpecification from "../../types/models/BvSpecification";
import { Accordion } from "react-bootstrap";


// this is a list of specifications renderer

type SpecificationsProps = {
  specifications: Array<BvSpecification>;
};

function Specifications({ specifications }: SpecificationsProps) {
  const renderItem = (item: BvSpecification) => {
    var props = {
      key: item.id,
      specification: item,
    };

    return(
        <Accordion defaultActiveKey="{item.id}">
          <Accordion.Item eventKey="item.id}">
            <Accordion.Header>{item.reference.title}</Accordion.Header>
            <Accordion.Body>
              <BVIACDataset {...props} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    )
  };

  return (
  <div>
    
    {specifications.map(renderItem)}
  
  </div>)
}

export default Specifications;
