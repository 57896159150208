import React from "react";
import RcvChart from "./RcvChart";
import {
  Col,
  Row,
  Card,
  Form,
  Table,
} from "react-bootstrap";

class PatientRCVCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      result1: 0,
      result2: 0,
      pctChange: 0,
      modellingDataSet: this.modellingDataSet,
      cvi: this.props.cvi,
      cva: this.props.cva,
      chart: this.props.chart,
    };

    this.handleResult1Change = this.handleResult1Change.bind(this);
    this.handleResult2Change = this.handleResult2Change.bind(this);
    this.calculatePercentageChange = this.calculatePercentageChange.bind(this);
  }

  handleResult1Change(event) {
    this.setState({ result1: event.target.value }, () =>
      this.calculatePercentageChange()
    );
  }

  handleResult2Change(event) {
    this.setState({ result2: event.target.value }, () =>
      this.calculatePercentageChange()
    );
  }

  calculatePercentageChange() {
    this.setState({
      pctChange:
        ((this.state.result2 - this.state.result1) / this.state.result1) * 100,
    });
  }

  render() {
    let rcvUp;
    let rcvDown;

    // if (this.state.pctChange > this.props.medianRCV.rcvUp)
    //   rcvUp = (
    //     <b>
    //       <p className="text-success">Above</p>
    //     </b>
    //   );
    // else
    //   rcvUp = (
    //     <b>
    //       <p className="text-success">Within Range</p>
    //     </b>
    //   );

    // if (this.state.pctChange < this.props.medianRCV.rcvDown)
    //   rcvDown = (
    //     <b>
    //       <p className="text-dager">Below</p>
    //     </b>
    //   );
    // else
    //   rcvDown = (
    //     <b>
    //       <p className="text-success">Within Range</p>
    //     </b>
    //   );

    return (
      <div>
        <h3>
          Application of the RCV
        </h3>
        <p>
          To assess the probability that a change between two consecutive laboratory 
          tests results from the same patient can be explained by analytical and biological 
          variation, calculate the percentage change by entering the test results below. 
        </p>
        <Card bg="warning">
          <Form>
            <Form.Group className="m-3">
              <Row>
                <Col md="6">
                  <label
                    htmlFor="result1"
                    className="grey-text font-weight-light"
                  >
                    1st Result
                  </label>
                  <input
                    type="number"
                    id="result1"
                    step="0.1"
                    className="form-control"
                    value={this.state.result1}
                    onChange={(e) => this.handleResult1Change(e)}
                  />
                </Col>

                <Col md="6">
                  <label
                    htmlFor="result2"
                    className="grey-text font-weight-light"
                  >
                    2nd Result
                  </label>
                  <input
                    type="number"
                    id="result2"
                    step="0.1"
                    className="form-control"
                    value={this.state.result2}
                    onChange={(e) => this.handleResult2Change(e)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card>
        <Table>
          <thead>
            <tr>
              <th>Result 1</th>
              <th>Result 2</th>
              <th>% Change</th>
              {/* <th>Exceeds RCV Increase</th>
                  <th>Exceeds RCV Decrease</th> */}
            </tr>
          </thead>
          <tbody>
            <tr className="font-monospace">
              <td>{Number(this.state.result1).toFixed(2)}</td>
              <td>{Number(this.state.result2).toFixed(2)}</td>
              <td>{Number(this.state.pctChange).toFixed(1)}</td>
              {/* <td>{rcvUp}</td>
                  <td>{rcvDown}</td> */}
            </tr>
          </tbody>
        </Table>
        <Card bg="light">
          <Card.Body>
            <p>
              If the “% Change” value shown above indicates a rise greater than 
              the one-sided “RCV% Increase” or a fall of more than the “RCV% Decrease”,
               then the change is greater than what is attributable to the 
               combined analytical and biological variation, at the chosen level of probability.  
            </p>
            <p>
              If p-value 0.05 is chosen when constructing a one-sided RCV, 
              hen in a similar fashion to a one-sided reference interval, 
              95% of the differences between two consecutive test results for 
              a stable steady-state situation would be expected to be within 
              this interval. This means that all the differences are caused by
               analytical and biological variation at the chosen level of probability. 
            </p>
          </Card.Body>
        </Card>
        <RcvChart
          rcvUpData={this.props.modellingDataSet[0]}
          rcvDownData={this.props.modellingDataSet[1]}
          pctChange={this.state.pctChange}
        />
      </div>
    );
  }
}

export default PatientRCVCalculator;
