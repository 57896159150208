import React from "react";
import { Card, CardGroup, Col, Container, Button, Row } from "react-bootstrap";
import MetaAnalysisCount from "./MetaAnalysisCount";
import BVSpecificationsCount from "./BVSpecificationsCount";
import ReferencesCount from "./ReferencesCount";

function OptionCards(props) {
    return (
        <Container>
            <CardGroup>
                <Card className="text-center">
                    <Card.Header className="bg-primary text-secondary">Meta - Analysis</Card.Header>
                    <Card.Body>
                        <Card.Title>List of BV estimates for all measurands</Card.Title>
                        <Card.Text>

                        </Card.Text>
                        <Button color="success" href="/meta_calculations">Go</Button>
                    </Card.Body>
                    <Card.Footer className="gray-500 text-info">Overview of meta-analysis derived BV estimates <br />with APS and RCV  calculation</Card.Footer>
                </Card>

                <Card className="text-center">
                    <Card.Header className="bg-primary text-secondary">List of all BV Estimates</Card.Header>
                    <Card.Body>
                        <Card.Title>View individual BV estimates</Card.Title>
                        <Card.Text>

                        </Card.Text>
                        <Button color="info" href="/bv_specifications">
                            Go
                        </Button>
                    </Card.Body>
                    <Card.Footer className="gray-500 text-info">Overview of all BV records with <br />publication details</Card.Footer>
                </Card>

                <Card className="text-center">
                    <Card.Header className="bg-primary text-secondary">Measurands</Card.Header>
                    <Card.Body>
                        <Card.Title>Show all Measurands</Card.Title>
                        <Card.Text>

                        </Card.Text>
                        <Button color="secondary" href="/bv_specifications/measurand">
                            Go
                        </Button>
                    </Card.Body>
                    <Card.Footer className="gray-500 text-info">Overview of BV data sets <br />for each measurand
                    </Card.Footer>
                </Card>
            </CardGroup>
            <Row>
                <section id='meta-data'>
                <CardGroup className="mt-3 ">
                    <Card className="text-center card-webster text-white small" >
                        <Card.Body >
                            Number of Meta-Analysis in Database <MetaAnalysisCount /> 
                        </Card.Body>
                    </Card>

                    <Card className="text-center card-webster  text-white small">
                        <Card.Body>
                                Number of Biological Variation Records <BVSpecificationsCount /> 
                        </Card.Body>
                    </Card>

                    <Card className="text-center card-webster text-white small">
                        <Card.Body>
                            Number of Papers Referenced <ReferencesCount /> 
                        </Card.Body>
                    </Card>
                </CardGroup>
                </section>
            </Row>
        </Container>
    )
}

export default OptionCards;