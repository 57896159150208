import React from "react";
import { Card, OverlayTrigger, Container, Tooltip } from 'react-bootstrap';
import CopyRight from "./CopyRight"

const renderCopyRight = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        <CopyRight />
    </Tooltip>
)

function ReferenceJumbotron(props) {
    return (
        <Container>
            <div className="bg-light p-2 rounded-lg m-1">

                    <Card className="card-body" >
                        <Card.Title>Use of data:</Card.Title>
                        <Card.Text className="text-muted">This website and its content is copyright of EFLM. You may not, except with our express written permission, distribute or commercially exploit the content (see copyright below).</Card.Text>
                        <Card.Text>
                            <span>
                            If using data from this website for any purpose, it should be referenced as:<br />
                            </span>
                            <span>
                                <b>Aarsand AK, Fernandez-Calle P, Webster C, Coskun A, Gonzales-Lao E, Diaz-Garzon J, 
                                    Jonker N, Simon M, Braga F, Perich C, Boned B, Marques-Garcia F, Carobene A, Aslan B, Sezer E, Bartlett WA, Sandberg S.    
                                </b>
                                <br/>The EFLM Biological Variation Database. https://biologicalvariation.eu/ [time of access].
                            </span>
                        </Card.Text >
                    </Card>

            </div>
        </Container>
    )
}

export default ReferenceJumbotron;