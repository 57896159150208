import React from "react";
import BvSpecification from "../../types/models/BvSpecification";

type PasteSpecificationButtonProps = {
  specification: BvSpecification;
};

function PasteSpecificationButton({
  specification,
}: PasteSpecificationButtonProps) {

  return (
    <a
      className="btn btn-info btn-sm btn-block"
      href={"/bv_specifications/" + specification.id + "/copy"}
      data-turbo="false"
    >
      Copy Dataset
    </a>
  );
}

export default PasteSpecificationButton;
