import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import MetacalculationCheck from "../MetaCalculation/MetaCalculationCheck";


function MetacalculationChecker(props) {

  const renderResults = (results) => {
    return (
      <><Table>
        <thead>
          <tr className="d-flex">
            <th className="col-1">Factor</th>
            <th className="col-3">Calculated</th>
            <th className="col-3">Original</th>
          </tr>
        </thead>
        <tbody>
          <tr className="d-flex">
            <td className="col-1">Type</td>
            <td className="col-3">{results.type}</td>
            <th className="col-3">Original</th>
        </tr>
          <tr className="d-flex">
            <td className="col-1">Analyte</td>
            <td className="col-3">{results.analyte}</td>
            <th className="col-3">Original</th>
        </tr>
          <tr className="d-flex">
            <td className="col-1">Matrix</td>
            <td className="col-3">{results.matrix}</td>
            <th className="col-3">Original</th>
        </tr>
          <tr className="d-flex">
            <td className="col-1">Results</td>
            <td className="col-3">{results.results["W.Median"]}</td>
            <th className="col-3"><MetacalculationCheck results={results} /></th>
        </tr>
          <tr className="d-flex">
            <td className="col-1">Specs</td>
            <td className="col-3">{results.specs.toString()}</td>
            <th className="col-3">Original</th>
        </tr>
        </tbody>
        </Table>
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2>Results</h2>
          {props.results.map(d => renderResults(d))} 
        </Col>
      </Row>
    </Container>
  );
}


export default MetacalculationChecker;
